import React from 'react';

import './search-bar.css';

export default function SearchBar({
  query,
  placeholder = 'Suche',
  onQueryChange,
  onSubmit = () => {},
}) {
  function handleSubmit(e) {
    e.preventDefault();
    onSubmit(e.target.query.value);
  }

  return (
    <form className="search-bar" onSubmit={handleSubmit}>
      <input
        type="text"
        name="query"
        className="search-bar__input"
        placeholder={placeholder}
        value={query}
        onChange={ e => onQueryChange(e.target.value) }
        autoComplete="off"
      />
      <div className="search-bar__icon">
        <i className="fas fa-search"></i>
      </div>
    </form>
  );
}
