import React from 'react';

import '../forecast-content.css';
import '../../styles/predictor.css';

export default function ForecastAutomatic({
  brand, model, year, priceFrom, priceTill, onFeedback,
}) {
  const priceFromFormatted = priceFrom.toLocaleString('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const priceTillFormatted = priceTill.toLocaleString('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return (
    <div data-testid="forecast-automatic">
      <div className="forecast-content__heading">
        <div className="forecast-content__text-large">
          Ihre Maschine:
        </div>
        {' '}
        <div className="forecast-content__text-large">
          { brand } { model }, Baujahr { year }
        </div>
      </div>
      <div className="forecast-content__text-normal" style={{ marginTop: '.5rem' }}>
        hat einen ungefähren Marktwert von:
      </div>

      <div className="forecast-content__price-block">
        <div className="forecast-content__price-text">
          { priceFromFormatted }
        </div>
        <b
          className="forecast-content__text-normal"
          style={{ margin: '0 1rem' }}
        >
          bis
        </b>
        <div className="forecast-content__price-text">
          { priceTillFormatted }
        </div>
      </div>

      <div className="forecast-content__feedback-text">
        Wie passend finden Sie diese Prognose?
      </div>

      <div className="forecast-content__button-block">
        <button
          className="predictor__button predictor__button_accent"
          onClick={ e => onFeedback(true) }
        >
          Gut
        </button>
        <span style={{ marginRight: '.5rem' }}></span>
        <button
          className="predictor__button predictor__button_default"
          onClick={ e => onFeedback(false) }
        >
          Schlecht
        </button>
      </div>
    </div>
  );
}
