import React, { useEffect } from 'react';

import './container.css';
import Navbar from '../navbar';
import Progress from '../progress';
import Main from '../main';
import Footer from '../footer';

export default function ContainerView({ onUnmount }) {
  useEffect(() => {
    return () => {
      onUnmount();
    };
  });
  
  return (
    <div className="predictor-container">
      <Navbar />
      <Progress />
      <Main />
      <Footer />
    </div>
  );
}
