import { OPEN_ARTICLE_MODAL, HANDLE_MODAL_HIDDEN, SET_SCROLLBAR_WIDTH } from './action-types';

const initialState = {
  modalArticle: null,
  scrollbarWidth: 0,
};

export default function articlesReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_ARTICLE_MODAL: {
      return Object.assign({}, state, {
        modalArticle: action.article,
      });
    }
    case HANDLE_MODAL_HIDDEN: {
      return Object.assign({}, state, {
        modalArticle: null,
        scrollbarWidth: 0,
      });
    }
    case SET_SCROLLBAR_WIDTH: {
      return Object.assign({}, state, {
        scrollbarWidth: action.width,
      });
    }
    default: return state;
  }
}
