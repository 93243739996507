import React from 'react';

import './main.css';
import getComponentByType from './get-component-by-type';

export default function MainView({ stepType }) {
  const StepComponent = getComponentByType(stepType);

  return (
    <div className="predictor-main">
      <StepComponent />
    </div>
  );
}
