import React from 'react';

import './manual.css';
import '../forecast-content.css';
import '../../styles/predictor.css';
import CustomCheckbox2 from '../../custom-checkbox/custom-checkbox-2';

export default function ForecastManualView({ brand, model, year, onSubmit }) {
  function handleSubmit(e) {
    e.preventDefault();
    const email = e.target.email.value;
    onSubmit(email);
  }

  return (
    <div data-testid="forecast-manual">
      <div className="forecast-content__heading">
        <div className="forecast-content__text-large">
          Ihre Maschine:
        </div>
        {' '}
        <div className="forecast-content__text-large">
          { brand } { model }, Baujahr { year }
        </div>
      </div>

      <div
        className="predictor__text"
        style={{ maxWidth: '33.5rem', margin: '.5rem auto 0' }}
      >
        Wir arbeiten daran zukünftig eine automatisierte Preisprognose 
        anzubieten. Bis es soweit ist nehmen wir jede Prüfung manuell vor.
      </div>

      <div className="forecast-content__price-block">
        <div className="forecast-content__price-text">
          Wir melden uns bei Ihnen!
        </div>
      </div>

      <p
        className="predictor__text predictor__text_large"
        style={{ marginBottom: '2rem' }}
      >
        Bitte teilen Sie uns Ihre E-Mail Adresse mit:
      </p>

      <form onSubmit={handleSubmit}>
        <div className="forecast-manual__input-container">
          <i
            className="far fa-envelope"
            style={{ marginRight: '.75rem', color: '#bbb' }}
          ></i>
          <input
            type="email"
            name="email"
            placeholder="Email Adresse"
            className="forecast-manual__input predictor__input"
            required
          />
        </div>

        <div style={{ marginBottom: '1rem' }}>
          <CustomCheckbox2 required>
            <span style={{ fontSize: '.9rem' }}>
              Ich bin mit der Verwendung meiner E-Mail Adresse zwecks Kontaktaufnahme einverstanden.
            </span>
          </CustomCheckbox2>
        </div>

        <button
          type="submit"
          className="predictor__button predictor__button_accent"
          style={{ marginBottom: '1rem' }}
        >
          Senden
        </button>
      </form>
    </div>
  );
}
