import React from 'react';

import './button-select.css';
import '../styles/custom-scrollbar.css';
import parseOptions from '../../lib/parse-options';
import ButtonSelectOption from './option';

function getActiveOptionId(stepId, values) {
  if (!values) {
    return null;
  }
  const valueRecord = values.find(x => x.stepId === stepId);
  if (!valueRecord) {
    return null;
  }
  const { value } = valueRecord;
  return value.id;
}

/**
 * ButtonSelect view component.
 * @param {*} param0 
 */
export default function ButtonSelectView({ stepId, content, values, onSelect }) {
  function renderOptions() {
    const options = parseOptions(stepId, content);
    const activeId = getActiveOptionId(stepId, values);
    return options.map((x, i) => (
      <ButtonSelectOption
        key={i}
        option={x}
        activeId={activeId}
        onClick={ e => onSelect(stepId, x) }
      />
    ));
  }

  return (
    <div
      data-testid="button-select"
      className="button-select custom-scrollbar"
    >
      <div className="button-select__grid">
        { renderOptions() }
      </div>
    </div>
  );
}

