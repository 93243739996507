import React from 'react';
import { Link } from 'react-router-dom';


export default function Footer() {
  const year = (new Date()).getFullYear();
  return (
    <section className="container-fluid bg-primary">
      <div className="container py-5">
        <h6 className="text-white font-weight-bold text-uppercase">Kontakt</h6>
        <div className="text-white font-weight-light">
          <div className="mt-2">Rheinlandmaschinen UG</div>
          <div className="mt-2">
            <span className="d-inline-block">Scheibenstrasse 49</span>
            <span className="text-accent text-wrap mx-2">|</span>
            <span className="d-inline-block">40479 Düsseldorf</span>
            <span className="text-accent text-wrap mx-2">|</span>
            <span className="d-inline-block">
              Telefon{' '}
              <a className="text-accent text-nowrap" href="tel: 0211 97 63 49 27">
                0211 97 63 49 27
              </a>
            </span>
            <span className="text-accent mx-2">|</span>
            <span className="d-inline-block">
              E-Mail:{' '}
              <a className="text-accent text-nowrap" href="mailto:info@hektor.one">
                info@hektor.one
              </a>
            </span>
          </div>
          <div className="mt-4">
            <span className="d-inline-block">© {year} Rheinlandmaschinen UG</span>
            {' '}
            <span className="text-accent mx-1">|</span>
            {' '}
            <a
              className="text-accent text-nowrap"
              href="https://facebook.com/hektor.one"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-square fa-lg text-white mr-1"></i>
              <span>facebook.com/hektor.one</span>
            </a>
            {' '}
            <span className="text-accent mx-1">|</span>
            {' '}
            <Link className="text-accent text-nowrap" to="/impressum">
              Impressum
            </Link>
            {' '}
            <span className="text-accent mx-1">|</span>
            {' '}
            <Link className="text-accent text-nowrap" to="/datenschutz">
              Datenschutz
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
