import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import './banner.scss';

export default function Banner() {
  useEffect(() => {
    const carouselElement = window.jQuery('#carouselBannerFade');
    // init carousel
    carouselElement.carousel();
    // cleaning memory up
    return () => carouselElement.carousel('dispose');
  });

  return (
    <section id="banner" className="position-relative">
      <div id="bannerOverlay" className="d-flex flex-column justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-10 col-md-8 col-xl-6 responsive-h2">
              <div className="text-white font-weight-800">Neutrale Bewertung von</div>
              <div id="carouselBannerFade" className="carousel slide carousel-fade" data-ride="carousel">
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <span className="text-accent font-weight-800">Traktoren und Landtechnik</span>
                  </div>
                </div>
              </div>
              <div className="mt-4 mb-0 mb-sm-5">
                <Link
                  className="btn btn-accent"
                  to="/bewertung"
                  style={{
                    marginRight: '1rem',
                    marginBottom: '1rem',
                  }}
                >
                  Kostenlos bewerten
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}