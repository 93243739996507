import React, { useState, useEffect } from 'react';

import './contact.scss';
import Form from './form/Form';
import Progress from './progress/Progress';

function getHeading(step) {
  switch (step) {
    case 'email': return 'Wie lautet deine E-Mail?';
    case 'question': return 'Stellen Sie uns Ihre Frage';
    case 'finish': return 'Vielen Dank, dass du uns geschrieben hast! Wir werden Sie in Kürze mit der Antwort auf Ihre Frage kontaktieren.';
    default: return 'Wie ist dein Name?';
  }
}

export default function Contact(props) {
  const [step, setStep] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [content, setQuestion] = useState(null);

  useEffect(() => {
    step === 'finish' && props.onComplete({ name, email, content });
  });

  function handleNext(value) {
    switch (step) {
      case 'email': {
        setEmail(value);
        return setStep('question');
      }
      case 'question': {
        setQuestion(value);
        return setStep('finish');
      }
      default: {
        setName(value);
        return setStep('email');
      }
    }
  }

  const style = {
    height: '180px',
    overflowY: 'visible',
  };

  return (
    <section id="contact" className="position-relative">
      <div id="contactOverlay"></div>
      <div className="position-relative pb-5">
        <div className="text-center">
          <div id="contactLabel" className="bg-accent px-4 px-sm-5 py-2 py-sm-3 mt-n3">
            <div className="corner"></div>
            <span className="text-white responsive-h2">
              <span className="font-weight-100">Gibt es </span>
              <span className="font-weight-900">Fragen?</span>
            </span>
          </div>
        </div>
        
        <div className="container text-center mt-5 py-4">
          <div className="mx-auto col-12 col-sm-10 col-md-8 col-lg-7 col-xl-6">
            <div className="d-flex flex-column justify-content-between" style={ style }>
              <h3 className="text-white font-weight-900">{ getHeading(step) }</h3>
              <Form step={ step } onNext={ handleNext }/>
            </div>
            <div className="d-flex mt-5" style={ { height: '45px' } }>
              <Progress step={ step } />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
