import React from 'react';
import Advantages from './components/advantages/Advantages';
//import Articles from './components/articles/Articles';
import Banner from './components/banner/Banner';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import ShiftableNavbar from './components/navbar/ShiftableNavbar';
//import ShiftableOperatorCard from './components/operator-card/ShiftableOperatorCard';
import Video from './components/video/Video';
//import stubArticles from './stub-articles';
import './App.scss';
import Help from './components/help/help';

const url = 'https://c9x1hg89w9.execute-api.us-east-1.amazonaws.com/dev/email/send'


function postMessage(url,data) {
  let content = {"email":data.mail,"name":data.name,"content":data.content}
  var req = new XMLHttpRequest();
  req.open("POST", url, true);
  req.setRequestHeader("Content-Type", "application/json");
  req.addEventListener("load", function () {
  });
  req.send(JSON.stringify(content));
}


function App() {
  function handleContact(data) {
    postMessage(url,data);
  }

  return (
    <div>
      <ShiftableNavbar />
      {/*<ShiftableOperatorCard />*/}
      <Banner />
      <Video />
      <Advantages />
      <Help/>
      <Contact onComplete={ handleContact } />
      <Footer />
    </div>
  );
}

export default App;
