import { connect } from 'react-redux';

import NumberListSelectView from './view';
import PredictorAction from '../../modules/predictor/action-type.enum';

function mapStateToProps(state) {
  return {
    stepId: state.predictor.step.id,
    content: state.predictor.content,
    searchPlaceholder: state.predictor.step.searchPlaceholder,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSelect: (stepId, option) => {
      dispatch({
        type: PredictorAction.STEP_SELECT,
        payload: {
          stepId,
          value: option,
        },
      });
    },
  };
}

const NumberListSelect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NumberListSelectView);

export default NumberListSelect;
