import React, { useState, useEffect } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
//import 'bootstrap/dist/css/bootstrap.min.css';

import './index.css';
import subAppReducer from './reducer';
import predictorSaga from './modules/predictor/saga';
import NavbarBase from '../components/navbar/NavbarBase';
import Container from './components/container';
import FooterCompact from '../components/footer/FooterCompact';

import steps from './data/steps';
//import contentGetters from './data/fixtures/content-getters';   // test
//import availabilities from './data/fixtures/availabilities';    // test
import contentGetters from './data/production/content-getters';   // prod
import availabilities from './data/production/availabilities';    // prod


const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  subAppReducer,
  applyMiddleware(sagaMiddleware),
);
sagaMiddleware.run(predictorSaga, { steps, contentGetters, availabilities });


export default function Predictor() {
  // height-100 fix for mobile
  const [windowInnerWidth, setInnerWidth] = useState(0);
  function setRelativeVH() {
    const newWidth = window.innerWidth;
    if (newWidth !== windowInnerWidth) {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      setInnerWidth(newWidth);
    }
  }
  useEffect(() => {
    setRelativeVH();
    window.addEventListener('resize', setRelativeVH);
    return () => window.removeEventListener('resize', setRelativeVH); 
  });
  // end height-100 fix

  return (
    <div className="predictor">
      <div className="predictor__navbar-underlay"></div>
      <NavbarBase />

      <div className="predictor__overlay">
        <div className="predictor__content container">
          <Provider store={store}>
            <Container />
          </Provider>
        </div>

        <div className="d-none d-lg-block fixed-bottom">
          <FooterCompact />
        </div>
      </div>
    </div>
  );
}
