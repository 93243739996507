import { connect } from 'react-redux';

import ForecastView from './view';
import PredictorAction from '../../modules/predictor/action-type.enum';
import StepId from '../../data/step-id.enum';

function mapStateToProps(state) {
  return {
    content: state.predictor.content,
    values: state.predictor.values,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onFeedback: (isGood, priceFrom, priceTill) => dispatch({
      type: PredictorAction.STEP_SELECT,
      payload: {
        stepId: StepId.FORECAST,
        value: { isGood, priceFrom, priceTill },
      }
    }),
  };
}

const Forecast = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForecastView);

export default Forecast;
