import React from 'react';

import ListSelect from '.';

/**
 * `ListSelect` wrapper with searching by numbers.
 * @param {*} props 
 */
export default function ListSelectNumberSearch(props) {
  return (
    <ListSelect
      searchEnabled={true}
      isNumberSearch={true}
      {...props}
    />
  );
}
