import { connect } from 'react-redux';

import ProgressView from './view';

function mapStateToProps(state) {
  return {
    index: state.predictor.step.index,
    num: state.predictor.step.num,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

const Progress = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProgressView);

export default Progress;
