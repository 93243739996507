// images for desktop
import traktor from "../img/agroliner2.png";
import hut from "../img/bg1.jpg";
import tractorCabin from "../img/bg2.jpg";
import aboutUsApp from "../img/backround_app.jpg";

// images for mobile
import traktorMobile from "../img/backround_app.jpg";
import hutMobile from "../img/backround_app.jpg";
import tractorCabinMobile from "../img/backround_app.jpg";

// images for tablet
import traktorTablet from "../img/backround_app.jpg";
import hutTablet from "../img/backround_app.jpg";
import tractorCabinTablet from "../img/backround_app.jpg";

import aboutUsBackground from "../img/backround_app.jpg";
import aboutUsBackgroundMobile from "../img/backround_app.jpg";
import aboutUsBackgroundTablet from "../img/backround_app.jpg";

import betriebsBackground from "../img/betriebsaufloesung.jpg";
import betriebsBackgroundMobile from "../img/betriebsaufloesung.jpg";
import betriebsBackgroundTablet from "../img/betriebsaufloesung.jpg";

import errorPageBackground from "../img/404_error.jpg";
import errorPageBackgroundMobile from "../img/backround_app.jpg";
import errorPageBackgroundTablet from "../img/backround_app.jpg";

export default function getImageForWidth(image) {
  const width = window.innerWidth;
  if (image === "tractor") {
    if (width < 600) {
      return traktorMobile;
    }
    if (width < 1024) {
      return traktorTablet;
    }
    return traktor;
  } else if (image === "hut") {
    if (width < 600) {
      return hutMobile;
    }
    if (width < 1024) {
      return hutTablet;
    }
    return hut;
  } else if (image === "cabin") {
    if (width < 600) {
      return tractorCabinMobile;
    }
    if (width < 1024) {
      return tractorCabinTablet;
    }
    return tractorCabin;
  } else if (image === "aboutUs") {
    if (width < 600) {
      return aboutUsBackgroundMobile;
    }
    if (width < 1024) {
      return aboutUsBackgroundTablet;
    }
    return aboutUsBackground;
  } else if (image === "betriebs") {
    if (width < 600) {
      return betriebsBackgroundMobile;
    }
    if (width < 1024) {
      return betriebsBackgroundTablet;
    }
    return betriebsBackground;
  } else if (image === "notFound") {
    if (width < 600) {
      return errorPageBackgroundMobile;
    }
    if (width < 1024) {
      return errorPageBackgroundTablet;
    }
    return errorPageBackground;
  } else if (image === "aboutUsApp") {
    if (width < 600) {
      return aboutUsApp;
    }
    if (width < 1024) {
      return aboutUsApp;
    }
    return aboutUsApp;
  }
}