import React, { Component } from 'react';

/* styles */
import './verticaltimeline.css'

class verticalTimeline extends Component {
  constructor(props) {
    super(props);
     this.state = {
       isBelow1025: false,
    };
   /* this.updateInnerWidth = this.updateInnerWidth.bind(this);*/
   /* this.animateInViewport = this.animateInViewport.bind(this);*/
  }

  componentDidMount() {
    /*this.updateInnerWidth();*/
    /* -> doesnt work properly on mobile devices, TODO -> this.animateInViewport('.verticalTimelineContainer', 'animatedRow') */
  /*  window.addEventListener("resize", this.updateInnerWidth); */
    /*window.addEventListener("scroll", () => this.animateInViewport('.verticalTimelineContainer', 'animatedRow')); */
}
  /*updateInnerWidth = () => {
      if (window.innerWidth < 1025) {
        this.setState({
          isBelow1025: true,
        })
      } else {
        this.setState({
          isBelow1025: false,
        })
      }
  }*/


  render() {
    return (
      <div className={this.props.class}>
        <div className="verticalTimelineHeader">{this.props.heading}</div>
          <div className="verticalTimelineWrapper">
            <div className="verticalTimelineReduced">
            {this.props.data.map((element, i) => 
              <div key={i} className="verticalTimelineContainer" id={`timeline${i}`}>
               <div className="verticalTimelineCard">
               {(element.alignment === "left" || this.state.isBelow1025) ? 
                <div className="contentCol">
                  <div className="mainPicture">
                    <div className="picCont">
                      <img src={element.image} alt={element.heading} title={element.heading} style={element.imageStyles} />
                    </div>
                    <div className="textCont">
                      <div className="textWrapper">
                        <h5><span>{element.number}</span> {element.heading}</h5>
                        <p>{element.paragraph}</p>
                      </div>
                    </div>
                  </div>
                </div> :                
                <div className="contentCol">
                  <div className="mainPicture">
                    <div className="textCont">
                      <div className="textWrapper">
                        <h5><span>{element.number}</span> {element.heading}</h5>
                        <p>{element.paragraph}</p>
                      </div>
                    </div>
                    <div className="picCont">
                      <img src={element.image} alt={element.heading} title={element.heading} style={element.imageStyles} />
                    </div>
                  </div>
                </div>
                }
                {(element.icon) ? <div className="arrowPicture">
                  <img src={(!this.state.isBelow1025) ? element.icon : element.mobileIcon} alt={element.heading} title={element.heading} />
                </div> : null
                }
              </div>
            </div>)}
          </div>
        </div>
      </div>
    );
  }
}

export default verticalTimeline;

