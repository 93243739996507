import ValueAction from './action-type.enum';

const initialValues = [];

export default function valueReducer(values = initialValues, action) {
  switch (action.type) {
    case ValueAction.VALUE_SET: {
      const { stepId, value } = action.payload;
      const newValues = values.map(x => (x.stepId !== stepId) && x);
      newValues.push({ stepId, value });
      return newValues;
    }
    case ValueAction.CLEAR_ALL: {
      return [];
    }
    default: return values;
  }
}
