import React, { useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';

export function Collapse(props) {
  useEffect(() => {
    const collapseElement = window.jQuery('#navbarCollapse');
    if (props.isExpanded) {
      collapseElement.collapse('show');
    } else {
      collapseElement.collapse('hide');
    }
  });

  return (
    <div className="collapse navbar-collapse" id="navbarCollapse">
      <ul className="navbar-nav mt-3 ml-3 ml-lg-auto">
        <li className="nav-item">
          <Link className="nav-link" to="/#video">So funktionierts</Link>
        </li>
        <li className="nav-item d-none d-lg-block">
          <span className="nav-link">|</span>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/#contact">Ihre Fragen</Link>
        </li>
        <li className="nav-item d-none d-lg-block">
          <span className="nav-link">|</span>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/about-us">Über uns</Link>
        </li>
        <li className="nav-item d-none d-lg-block">
          <span className="nav-link">|</span>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/bewertung">Bewerten</Link>
        </li>
        <li className="nav-item d-none d-lg-block">
          <span className="nav-link">|</span>
        </li>
        <li className="nav-item d-none d-lg-block">
          <span className="nav-link font-weight-900">
            <span className="fas fa-phone-alt mr-2"></span>
            <span>0211 97 63 49 27</span>
          </span>
        </li>
      </ul>
    </div>
  );
}

export default withRouter(props => <Collapse { ...props } />);