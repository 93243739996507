/**
 * Power options production data.
 */

const powerOptions = [
  {
    id: '0 - 50',
    label: '0 - 50',
    opts: {
      min: 0,
      max: 50,
    },
  }
];

// incrementing 50
for (let i = 50; i < 600; i += 50) {
  const min = i + 1;
  const max = i + 50;
  const label = `${min} - ${max}`;
  powerOptions.push({
    id: label,
    label,
    opts: {
      min,
      max,
    },
  });
}

powerOptions.push({
  id: '601 - ...',
  label: '601 - ...',
  opts: {
    min: 601,
  },
});

export default powerOptions;
