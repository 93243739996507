import StepId from '../../step-id.enum';

export default function makeGetMachineTypes(categories) {
  return function(values) {
    const { id } = values
      .find(x => x.stepId === StepId.CATEGORY)
      .value;
    const category = categories.find(x => x.id === id);
    return category.machineTypes || [];
  }
}
