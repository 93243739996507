import StepId from './step-id.enum';
import StepType from './step-type.enum';

/**
 * Production step map.
 */
const steps = [
  {
    id: StepId.CATEGORY,
    type: StepType.BUTTON_SELECT,
    heading: 'Welche Maschinenkategorie möchten Sie bewerten lassen?',
  },
  {
    id: StepId.MACHINE_TYPE,
    type: StepType.BUTTON_SELECT,
    heading: 'Bitte wählen Sie Ihren Maschinentyp',
  },
  {
    id: StepId.EMPTY_STATE,
    type: StepType.EMPTY_STATE,
  },
  {
    id: StepId.BRAND,
    type: StepType.SEARCH_LIST_SELECT,
    heading: 'Bitte wählen Sie Ihren Hersteller',
    searchPlaceholder: 'Hersteller auswählen',
  },
  {
    id: StepId.MODEL,
    type: StepType.SEARCH_LIST_SELECT,
    heading: 'Bitte wählen Sie Ihren Modell',
    searchPlaceholder: 'Gewünschtes Modell auswählen',
  },
  {
    id: StepId.POWER,
    type: StepType.LIST_SELECT_NUMBER_SEARCH,
    heading: 'Welche Leistung (in PS) hat Ihre Maschine?',
    searchPlaceholder: 'Leistung in PS angeben',
  },
  {
    id: StepId.YEAR,
    type: StepType.SEARCH_LIST_SELECT,
    heading: 'Aus welchem Baujahr stammt die Maschine?',
    searchPlaceholder: 'Baujahr auswählen',
  },
  {
    id: StepId.HOUR,
    type: StepType.NUMBER_LIST_SELECT,
    heading: 'Wie viele Betriebsstunden hat Ihre Maschine?',
    searchPlaceholder: 'Betriebsstunden angeben',
  },
  {
    id: StepId.HECTARE,
    type: StepType.PLAIN_LIST_SELECT,
    heading: 'Geschätzte Hektarleistung pro Jahr?',
  },
  {
    id: StepId.CONDITION,
    type: StepType.PLAIN_LIST_SELECT,
    heading: 'In welchem Zustand befindet sich ihre Maschine?',
  },
  {
    id: StepId.TIRES_CONDITION,
    type: StepType.PLAIN_LIST_SELECT,
    heading: 'In welchem Zustand befindet sich die Reifen?',
  },
  {
    id: StepId.ADD_ONS,
    type: StepType.CHECKBOX_LIST,
    heading: 'Welche Sonderausstattungen hat Ihre Maschine?',
  },
  /*{
    id: StepId.PRIMARY_FORECAST,
    type: StepType.PRIMARY_FORECAST,
    heading: 'Primary Forecast (TEST)',   // for testing purposes
  },*/
  {
    id: StepId.FORECAST,
    type: StepType.FORECAST,
  },
  {
    id: StepId.SIGN_UP,
    type: StepType.SIGN_UP,
  },
  {
    id: StepId.FINISH,
    type: StepType.FINISH,
  },
];

export default steps;
