/**
 * Production machine conditions data.
 */

const conditions = [
  { id: 'full-op', label: 'Vollständig einsatzbereit' },
  { id: 'small-defect', label: 'Kleinere Defekte' },
  { id: 'no-op', label: 'Nicht einsatzbereit' },
];

export default conditions;
