import '@fortawesome/fontawesome-free/css/all.css';
import './styles/bootstrap-theme.scss';
import './index.scss';
import { createBrowserHistory } from 'history';
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import { createStore, combineReducers } from 'redux';
import About from './components/aboutus/about';
import Betrieb from './components/betriebsaufloesung/about';
import Inserate from './components/inserate/inserate';

import articlesReducer from './components/articles/reducer';
import Imprint from './components/imprint/imprint';
import PrivacyPolicy from './components/privacy-policy/privacy-policy';
import ConfirmationPage from './components/confirmationPage/confirmationPage.js';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Predictor from './predictor';
import PredictorRedirect from './predictor/redirect';

const store = createStore(combineReducers({
  articles: articlesReducer,
}));

// https://stackoverflow.com/questions/40280369/use-anchors-with-react-router
const browserHistory = createBrowserHistory();
browserHistory.listen(location => {
  const { hash } = location;
  if (hash !== '') {
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(
      () => {
        const offset = document.querySelector(hash).offsetTop - 110;
        window.jQuery('html, body').animate({
          scrollTop: offset
        }, 1000);
        // window.jQuery('#navbarCollapse').collapse('hide');
      },
      0,
    );
  } else if (window.screenTop > 0) {
    window.jQuery('html, body').animate({
      scrollTop: 0
    }, 1000);
  }
});

ReactDOM.render(
  <Provider store={ store }>
    <CookieConsent
      disableStyles
      containerClasses="cookiebarContainer"
      buttonText={<div className="closeContainer">OK</div>}
      cookieName="hektorCookie"
      expires={150}
      buttonClasses="closeWrapper"
      contentClasses="contentCookieWrapper"
    >
      <div className="cookieTextContainer">
        <span className="cookieText">
          Wir nutzen Cookies, um die Nutzung der Seite für Sie zu
          verbessern.{" "}
        </span>
        <span>
          <a className="cookieLink" href="/datenschutz" title="Mehr Infos">Mehr Infos</a>
        </span>
      </div>
    </CookieConsent>
    <Router history={browserHistory}>
      <Route path="/" exact component={App} />
      <Route path="/about-us" component={About} />
      <Route path="/betriebsaufloesung" component={Betrieb} />
      <Route path="/inserate" component={Inserate} />
      <Route path="/impressum" component={Imprint} />
      <Route path="/datenschutz" component={PrivacyPolicy} />
      <Route path="/anmeldung" component={ConfirmationPage} />

      <Route path="/predictor" component={PredictorRedirect} />
      <Route path="/bewertung" component={Predictor} />
    </Router>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
