import { put } from 'redux-saga/effects';

import ValueAction from './action-type.enum';

export default function* valueRegisterSaga(stepId, value) {
  yield put({
    type: ValueAction.VALUE_SET,
    payload: { stepId, value },
  });
}
