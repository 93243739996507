import React, { useEffect } from 'react';

import './form.scss';
import rightArrow from './right-arrow.svg';

export default function Form(props) {
  const step = props.step;

  function handleSubmit(event) {
    event.preventDefault();
    props.onNext(event.target.q.value);
    event.target.q.value = '';
  }

  useEffect(() => {
    const textField = document.getElementById('contactTextField');
    props.step && textField && textField.focus();
  });

  return (
    step !== 'finish' && (
      <form onSubmit={handleSubmit} className="mt-0" id="contactForm" action="#" method="get">
        <input type={step === 'email' ? 'email' : 'text'} id="contactTextField" name="q" required />
        <button type="submit" className="mt-2">
          <img src={rightArrow} alt="contact step" />
        </button>
      </form>
    )
  );
}
