import React from 'react';

import './navbar.css';
import getBackVisible from '../../lib/get-back-visible';
import getForwardVisible from '../../lib/get-forward-visible';

export default function NavbarView({
  index, stepId, stepType, heading, content, values, onForward, onBack,
}) {
  function renderBackButton() {
    if (!getBackVisible({ index, stepId })) {
      return null;
    }
    return (
      <button
        className="predictor-navbar__button-link"
        data-testid="back-button"
        onClick={ e => onBack() }
      >
        <i className="fas fa-chevron-left fa-lg"></i>
        <span style={{ marginLeft: '.5rem' }}>Zurück</span>
      </button>
    );
  }

  function renderForwardButton() {
    if (!getForwardVisible({ stepType, stepId, values, content })) {
      return null;
    }
    return (
      <button
        className="predictor-navbar__button-link"
        data-testid="forward-button"
        onClick={ e => onForward() }
      >
        <span style={{ marginRight: '.5rem' }}>Weiter</span>
        <i className="fas fa-chevron-right fa-lg"></i>
      </button>
    );
  }

  const headingStyle = !heading ? { padding: 0 } : {};

  return (
    <nav className="predictor-navbar">
      <div className="predictor-navbar__button-wrapper">
        { renderBackButton() }
      </div>

      <div
        data-testid="navbar__heading"
        className="predictor-navbar__heading"
        style={headingStyle}
      >
        { heading }
      </div>

      <div className="predictor-navbar__button-wrapper">
        { renderForwardButton() }
      </div>
    </nav>
  );
}
