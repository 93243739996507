import React from 'react';
import { Link } from 'react-router-dom';


export default function FooterCompact() {
  const year = (new Date()).getFullYear();
  return (
    <footer className="container-fluid bg-primary">
      <div className="container py-2">
        <div style={{ color: 'white', fontSize: '.9rem', fontWeight: 500 }}>
          <span className="d-inline-block">© {year} Rheinlandmaschinen UG</span>
          <span className="mx-3">|</span>
          <Link className="text-accent text-nowrap" to="/impressum">
            Impressum
          </Link>
          <span className="mx-3">|</span>
          <Link className="text-accent text-nowrap" to="/datenschutz">
            Datenschutz
          </Link>
        </div>
      </div>
    </footer>
  );
}
