import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Footer from '../footer/Footer';
import ShiftableNavbar from '../navbar/ShiftableNavbar';

import './confirmationPage.scss';

export default class ConfirmationPage extends Component {
  render() {
    return (
      <div>
        <ShiftableNavbar />
        <div className={"subpageBody"}>
          <div className={"tempSpacer"}>&nbsp;</div>
            <div className="subpageContainer">
              <div className="impressumContent">
                <h3>Sie haben Post!</h3>
                <p>Sie sind jetzt bei Hektor.ONE angemeldet. Danke für die Anmeldung. </p>
                <p >Wir senden Ihnen zeitnah eine statistische Marktpreiseinschätzung zu Ihrer gebrauchten Landmaschine.</p>
                <Link to="../" title="home" className={"linkButton"}>Zur Startseite</Link>
              </div>
            </div>
        </div>
        <Footer />
      </div>
    );
  }
};