import React, { Component } from "react";

//components and data
import BannerAbout from "./bannerAbout.js";
import FlipCard from "./flipCard.js";
import VerticalTimeline from "./verticalTimeline.js";
import InfoText from "./infoText.js";
import ShiftableNavbar from '../navbar/ShiftableNavbar';
import ShiftableOperatorCard from '../operator-card/ShiftableOperatorCard';

//media
import arrow_circle_right from "../../img/icons/arrow_circle_right.svg";
import arrow_circle_left from "../../img/icons/arrow_circle_left.svg";
import arrow_down from "../../img/icons/arrow_down.svg";
import step1 from "../../img/process1.png";
import step2 from "../../img/process2.png";
import step3 from "../../img/process3.png";
import step4 from "../../img/process4.png";
import step5 from "../../img/process5.png";
import Shield from "../../img/icons/Protect_white.svg";
import MoneyPig from "../../img/icons/Money_pig_white.svg";
import Konfiguration from "../../img/icons/Konfiguration_white.svg";

import getImageForWidth from "../../utils/imageSelector";

import Footer from '../footer/Footer';

//styles
import "./about.css";

export default class About extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const flipCardData = [
      {
        index: 0,
        heading: "Erfolgsabhängig",
        paragraph: (
          <span>
            kein Risiko
          </span>
        ),
        icon: Shield,
        flippedParagraph: (
          <span>
            Wir haben es uns zur <span className="strong">Aufgabe</span>{" "}
            gemacht, eine <span className="strong">digitale Losung</span> fur
            das Problem intransparenter.
          </span>
        )
      },
      {
        index: 1,
        heading: "Mehreinnahmen",
        paragraph: "keine Zwischenmänner",
        icon: MoneyPig,
        flippedParagraph: (
          <span>
            Wir haben es uns zur <span className="strong">Aufgabe</span>{" "}
            gemacht, eine <span className="strong">digitale Losung</span> fur
            das Problem intransparenter.
          </span>
        )
      },
      {
        index: 2,
        heading: "Fachkompetenz",
        paragraph: "Inspektion durch Techniker",
        icon: Konfiguration,
        flippedParagraph: (
          <span>
            Wir haben es uns zur <span className="strong">Aufgabe</span>{" "}
            gemacht, eine <span className="strong">digitale Losung</span> für
            das Problem intransparenter.
          </span>
        )
      }
    ];

    const verticalTimelineData = [
      {
        index: 0,
        number: "1",
        alignment: "left",
        heading: "Anfrage stellen",
        paragraph:
          "Füllen Sie unser kurzes Abfrageformular aus, um Ihre unverbindliche Verkaufsanfrage an uns zu übermitteln.",
        image: step1,
        icon: arrow_circle_left,
        mobileIcon: arrow_down
      },
      {
        index: 1,
        number: "2",
        alignment: "right",
        heading: "Inspektion terminieren",
        paragraph:
          "Wir rufen Sie zurück, um einen Termin für die Maschineninspektion festzulegen. ",
        image: step2,
        icon: arrow_circle_right,
        mobileIcon: arrow_down
      },
      {
        index: 2,
        number: "3",
        alignment: "left",
        heading: "Inspektion und Fotos",
        paragraph:
          "Wir inspizieren die Maschine zum vereinbarten Termin und erstellen hochwertige Aufnahmen.",
        image: step3,
        imageStyles: { maxWidth: "250px" },
        icon: arrow_circle_left,
        mobileIcon: arrow_down
      },
      {
        index: 3,
        number: "4",
        alignment: "right",
        heading: "Inserat erstellen",
        paragraph:
          "Wir listen Ihr Inserat auf den großen Marktplätzen und nehmen den Erstkontakt mit Interessenten auf.",
        image: step4,
        imageStyles: { maxWidth: "350px" }
      },
      {
        index: 3,
        number: "5",
        alignment: "left",
        heading: "Verkaufsabwicklung",
        paragraph:
          "Wir leiten seriöse Preisvorschläge an Sie weiter. Gerne übernehmen wir auch die vertragliche Abwicklung.",
        image: step5,
        imageStyles: { maxWidth: "350px" }
      }
    ];
    return (
       
      <div className="aboutusContainer">
       <ShiftableNavbar />
        <ShiftableOperatorCard />  
        <BannerAbout
          backgroundImage={getImageForWidth("betriebs")}
          customId={"about-us-banner-picture"}
          isButtonDisabled
          customMinHeight="320px"
        />
        <InfoText />
        <FlipCard
          data={flipCardData}
          class={"standardFlipCard"}
          heading={
            <h1>
              <span>Ihre</span> Vorteile
            </h1>
          }
        />
        <VerticalTimeline
          data={verticalTimelineData}
          class={"standardVerticalTimeline"}
          heading={
            <h1>
              <span>So</span> funktioniert es
            </h1>
          }
        />
        <Footer/>
      </div>
    );
  }}