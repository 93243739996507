/**
 * Production hectare per year values data.
 */

const hectares = [
  { id: 50, label: 'Bis 50ha' },
  { id: 100, label: 'Bis 100ha' },
  { id: 150, label: 'Bis 150ha' },
  { id: 200, label: 'Bis 200ha' },
  { id: 250, label: 'Bis 250ha' },
  { id: 300, label: 'Bis 300ha' },
  { id: 350, label: 'Bis 350ha' },
  { id: 400, label: 'Bis 400ha' },
  { id: 450, label: 'Bis 450ha' },
];

export default hectares;
