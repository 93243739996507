import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';

import brand from './brand.png';
import Collapse from './Collapse';
import './navbar.css';
import './app-navbar.css';

export function Navbar({ offset, location }) {
  const [isBgPrimary, setBgPrimary] = useState(false);
  const [isExpanded, setExpanded] = useState(false);

  // navbar-sticky theme determiner
  function setNavbarBgClass() {
    setBgPrimary(
      isExpanded ||
      window.pageYOffset > 1 ||
      ['/impressum', '/datenschutz', '/anmeldung']
        .includes(location.pathname)
    );
  }

  function handleToggleCollapse() {
    setExpanded(x => !x);
  }

  function handleHide() {
    setExpanded(false);
  }

  // navbar theme on scroll
  useEffect(() => {
    setNavbarBgClass();
    document.addEventListener('scroll', setNavbarBgClass);
    return () => {
      document.removeEventListener('scroll', setNavbarBgClass);
    };
  });

  const style = {
    width: `calc(100% - ${offset}px)`,
  };
  const bgClassName = isBgPrimary ? 'bg-primary' : '';
  const navbarClassName = 
    `app-navbar navbar navbar-expand-lg navbar-dark ${bgClassName}`;

  return (
    <nav className={navbarClassName} style={style}>
      <Link className="app-navbar__brand navbar-brand" to="/">
        <img
          className="app-navbar__brand-image"
          src={brand}
          alt="brand"
        />
      </Link>
      <button
        onClick={handleToggleCollapse}
        className="navbar-toggler"
        style={{ border: 'none' }}
        type="button"
        data-toggle="_collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span
          className="navbar-toggler-icon"
          style={{ width: '1.25em', height: '1.25em' }}
        ></span>
      </button>

      <Collapse isExpanded={isExpanded} onHide={handleHide} />
    </nav>
  );
}

export default withRouter(Navbar);