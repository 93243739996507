import React, { useState, useEffect } from 'react';

import '../styles/step-frame.css';
import parseOptions from '../../lib/parse-options';
import BaseListSelect from '../base-list-select';
import SearchBar from '../search-bar';

export default function SearchListSelectView({
  stepId, content, searchPlaceholder,
}) {
  const [query, setQuery] = useState('');

  // resetting `query` for new step
  useEffect(() => setQuery(''), [stepId]);

  const options = parseOptions(stepId, content)
    .filter(x => {
      if (!query) {
        return x;
      }
      const pattern = new RegExp(query, 'i');
      return x.label.toString().match(pattern);
    });
  
  return (
    <div className="step-frame">
      <div className="step-frame__content">
        <SearchBar
          placeholder={searchPlaceholder}
          query={query}
          onQueryChange={setQuery}
        />
        <BaseListSelect options={options} />
      </div>
    </div>
  );
}
