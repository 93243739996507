import { connect } from 'react-redux';

import Navbar from './Navbar';

const mapStateToProps = state => ({
  offset: state.articles.scrollbarWidth,
});

export default connect(
  mapStateToProps,
  null,
)(Navbar);
