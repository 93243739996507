import { call, select, take } from 'redux-saga/effects';

import PredictorAction from './action-type.enum';
import { valueSelector } from './selectors';
import getStepSaga from '../step/get-step-saga';
import stepSetSaga from '../step/step-set-saga';

export default function* stepBackSaga(predictorParams) {
  while (true) {
    yield take(PredictorAction.STEP_BACK);

    // getting registered values
    const values = yield select(valueSelector);

    // getting previous available step
    const stepData = yield call(getStepSaga, predictorParams, values, true);
    const { step, index } = stepData;

    // setting step
    yield call(stepSetSaga, step, index);
  }
}
