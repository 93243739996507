import { connect } from 'react-redux';

import ContainerView from './view';
import PredictorAction from '../../modules/predictor/action-type.enum';

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    onUnmount: () => dispatch({ type: PredictorAction.RESTART }),
  }
}

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContainerView);

export default Container;
