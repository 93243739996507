import { put } from 'redux-saga/effects';

import StepAction from './action-type.enum';

export default function* stepSetSaga(step, index) {
  const { id, type, heading, searchPlaceholder, opts } = step;
  yield put({
    type: StepAction.STEP_SET,
    payload: {
      index,
      id,
      type,
      heading,
      searchPlaceholder,
      opts,
    },
  });
}
