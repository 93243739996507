import StepId from '../../step-id.enum';
import categories from '../categories';
import alignCategoryWithType from '../../getters/helpers/align-category-with-type';

//const backendUrl = 'http://localhost:3001/forecast';  // env, HARDCODE
const backendUrl =
  //'https://ce4r91cyj4.execute-api.eu-central-1.amazonaws.com/Prod/predict'; // HARDCODE
  //'https://6esgx2ggn7.execute-api.eu-central-1.amazonaws.com/Prod/predict'; // HARDCODE
  'https://97rdzh0gma.execute-api.eu-central-1.amazonaws.com/Prod/predict'  // HARDCODE

/**
 * Forecast content getter that fetches 
 * forecast data from **real backend**.
 * @param {*} values 
 */
export default async function getForecastContent(values) {
  const { categoryId, machineTypeId } = alignCategoryWithType(categories, values);
  const brand = values
    .find(x => x.stepId === StepId.BRAND)
    .value
    .id;
  const model = values
    .find(x => x.stepId === StepId.MODEL)
    .value
    .id;
  const year = values
    .find(x => x.stepId === StepId.YEAR)
    .value
    .label;

  let powerLower = 0;
  const powerRecord = values.find(x => x.stepId === StepId.POWER);
  if (powerRecord) {
    powerLower = powerRecord.value.id.split(' - ')[0];
  }
  //console.log({ powerLower });

  let hourLower = 0;
  const hourRecord = values.find(x => x.stepId === StepId.HOUR);
  if (hourRecord) {
    hourLower = hourRecord.value.id.split(' - ')[0];
  }
  //console.log({ hourLower });

  const bodyObj = {
    sub_category: machineTypeId || categoryId,
    brand,
    model,
    year,
    PS: +powerLower,
    engine_hours: +hourLower,
  };

  const addOnRecord = values.find(x => x.stepId === StepId.ADD_ONS);
  if (addOnRecord) {
    const addOns = addOnRecord.value;
    addOns.map(x => bodyObj[x.id] = true);
  }

  // demo body - TODO: remove
  /*const body = {
    "brand": "john deere",
    "PS": 220,
    "engine_hours": 3000,
    "category": "Traktoren",
    "sub_category": "Traktoren",
    "year": 2011
  }*/

  const response = await fetch(backendUrl, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyObj),
  });
  const data = await response.json();
  const { lower, upper } = data.prediction;

  return {
    brand,
    model,
    year,
    priceFrom: lower,
    priceTill: upper,
  };
}
