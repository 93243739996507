import { connect } from 'react-redux';

import EmptyStateView from './view';
import PredictorAction from '../../modules/predictor/action-type.enum';
import StepId from '../../data/step-id.enum';


function stp(s) {
  return {};
}

function dtp(d) {
  return {
    onSubmit: email => d({
      type: PredictorAction.STEP_SELECT,
      payload: {
        stepId: StepId.EMPTY_STATE,
        targetStepId: StepId.FINISH,
        value: { email },
      },
    }),
  };
}


const EmptyState = connect(
  stp,
  dtp,
)(EmptyStateView);

export default EmptyState;
