import React from 'react';

import './operator-card.css';
import operatorImage from './marten.png';

export default function OperatorCard({ offset }) {
  return (
    <div
      id="operatorCard"
      style={{ right: `${offset}px` }}
      className="d-none d-lg-block bg-primary text-white text-center px-3 py-4"
    >
      <div className="font-weight-light">Ihr persönlicher Ansprechpartner</div>
      <div className="mt-3">
        <img className="rounded-circle" src={operatorImage} alt="contact person avatar" />
      </div>
      <div className="h4 mt-3">Marten Telake</div>
      <div>
        <span className="fas fa-phone-alt mr-2"></span>
        <span>0211 97 63 49 27</span>
      </div>
    </div>
  );
}
