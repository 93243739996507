import { connect } from 'react-redux';

import SignUpView from './view';
import PredictorAction from '../../modules/predictor/action-type.enum';
import StepId from '../../data/step-id.enum';

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: email => {
      dispatch({
        type: PredictorAction.STEP_SELECT,
        payload: {
          stepId: StepId.SIGN_UP,
          value: { email },
        },
      });
    },
  };
}

const SignUp = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignUpView);

export default SignUp;
