import { call, put, select } from 'redux-saga/effects';

import StepAction from '../step/action-type.enum';
import loadContentSaga from '../content/load-content-saga';
import { valueSelector } from './selectors';

export default function* setStepSaga(predictorParams, index) {
  const { steps, contentGetters } = predictorParams;
  const { id, type, heading } = steps[index];

  const values = yield select(valueSelector);
  yield call(loadContentSaga, contentGetters, id, values);

  yield put({
    type: StepAction.STEP_SET,
    payload: {
      index,
      id,
      type,
      heading,
    },
  });
}
