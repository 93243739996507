import React, { Component } from 'react';

/* styles */
import './flipCard.css'

class FlipCard extends Component {
  constructor(props) {
    super(props);
     this.state = {
    };
  }

  render() {
    return (
      <div className={this.props.class}>
        <div className="flipHeader">{this.props.heading}</div>
          <div className="flipWrapper">
            <div className="flipReduced">
            {this.props.data.map((element, i) => 
              <div key={i} className="flipContainer">
               <div className="side">
                <img src={element.icon} alt={element.heading} title={element.heading} />
                <h5>{element.heading}</h5>
                <p>{element.paragraph}</p>
              </div>
              {/* <div className="side back">
                <p>{element.flippedParagraph}</p>
            </div> */}
            </div>)
          }
          </div>
        </div>
      </div>
    );
  }
}

export default FlipCard;