import React, { Component } from "react";

//components and data
import InfoText from "./infoText.js";
import ShiftableNavbar from '../navbar/ShiftableNavbar';
import ShiftableOperatorCard from '../operator-card/ShiftableOperatorCard';
import Footer from '../footer/Footer';

//styles
import "./about.css";

export default class Inserate extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
       
      <div className="aboutusContainer">
       <ShiftableNavbar />
        <ShiftableOperatorCard />  
        
        <InfoText />
        
        
        <Footer/>
      </div>
    );
  }}