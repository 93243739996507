import { connect } from 'react-redux';

import CheckboxListView from './view';
import ValueAction from '../../modules/values/action-type.enum';

function mapStateToProps(state) {
  return {
    stepId: state.predictor.step.id,
    content: state.predictor.content,
    values: state.predictor.values,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onChange: (stepId, value) => {
      dispatch({
        type: ValueAction.VALUE_SET,
        payload: {
          stepId,
          value,
        }
      });
    },
  };
}

const CheckboxList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CheckboxListView);

export default CheckboxList;
