const StepId = {
  CATEGORY: 'CATEGORY',
  MACHINE_TYPE: 'MACHINE_TYPE',
  MANUFACTURER: 'MANUFACTURER',
  BRAND: 'MANUFACTURER',  // alias for MANUFACTURER
  MODEL: 'MODEL',
  POWER: 'POWER',
  YEAR: 'YEAR',
  HOUR: 'HOUR',
  HECTARE: 'HEACTARE',
  CONDITION: 'CONDITION',
  TIRES_CONDITION: 'TIRES_CONDITION',
  ADD_ONS: 'ADD_ONS',
  FORECAST: 'FORECAST',
  SIGN_UP: 'SIGN_UP',
  FINISH: 'FINISH',
  EMPTY_STATE: 'EMPTY_STATE',
  // for testing purposes
  PRIMARY_FORECAST: 'PRIMARY_FORECAST', // deprecated
  TEST: 'TEST',
  TEST_0: 'TEST_0',
  TEST_1: 'TEST_1',
};

export default StepId;
