import { connect } from 'react-redux';

import FooterView from './view';
import PredictorAction from '../../modules/predictor/action-type.enum';

function mapStateToProps(state) {
  return {
    index: state.predictor.step.index,
    stepId: state.predictor.step.id,
    stepType: state.predictor.step.type,
    heading: state.predictor.step.heading,
    content: state.predictor.content,
    values: state.predictor.values,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onForward: () => dispatch({ type: PredictorAction.STEP_FORWARD }),
    onBack: () => dispatch({ type: PredictorAction.STEP_BACK }),
  };
}

const Footer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FooterView);

export default Footer;
