import { connect } from 'react-redux';

import ListSelectView from './view';
import PredictorAction from '../../modules/predictor/action-type.enum';

function mapStateToProps(state) {
  return {
    content: state.predictor.content,
    stepId: state.predictor.step.id,
    values: state.predictor.values,
    searchPlaceholder: state.predictor.step.searchPlaceholder,
    stepOpts: state.predictor.step.opts,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSelect: (stepId, value) => {
      dispatch({
        type: PredictorAction.STEP_SELECT,
        payload: { stepId, value },
      });
    },
  };
}

const ListSelect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListSelectView);

export default ListSelect;
