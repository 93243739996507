import React from 'react';

import './progress.css';

export default function ProgressView({ index, num }) {
  return (
    <progress
      className="predictor-progress"
      value={index}
      max={num - 1}
    />
  );
}
