import { connect } from 'react-redux';

import OperatorCard from './OperatorCard';

const mapStateToProps = state => {
  return {
    offset: state.articles.scrollbarWidth,
  }
};

export default connect(
  mapStateToProps,
  null,
)(OperatorCard);
