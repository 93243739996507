import StepType from '../../data/step-type.enum';
import ButtonSelect from '../button-select';
import PlainListSelect from '../plain-list-select';
import SearchListSelect from '../search-list-select';
import NumberListSelect from '../number-list-select';
import ListSelect from '../list-select';  // deprecated
import ListSelectNumberSearch from '../list-select/number-search';  // deprecated
import CheckboxList from '../checkbox-list';
import PrimaryForecast from '../primary-forecast';  // deprecated
import Forecast from '../forecast';
import SignUp from '../sign-up';
import Finish from '../finish';
import ErrorScreen from '../error-screen';
import EmptyState from '../empty-state';

export default function getComponentByType(stepType) {
  switch (stepType) {
    case StepType.BUTTON_SELECT: {
      return ButtonSelect;
    }
    case StepType.PLAIN_LIST_SELECT: {
      return PlainListSelect;
    }
    case StepType.SEARCH_LIST_SELECT: {
      return SearchListSelect;
    }
    case StepType.NUMBER_LIST_SELECT: {
      return NumberListSelect;
    }
    case StepType.LIST_SELECT: {
      return ListSelect;
    }
    case StepType.LIST_SELECT_NUMBER_SEARCH: {
      return ListSelectNumberSearch;
    }
    case StepType.CHECKBOX_LIST: {
      return CheckboxList;
    }
    case StepType.PRIMARY_FORECAST: {
      return PrimaryForecast;
    }
    case StepType.FORECAST: {
      return Forecast;
    }
    case StepType.SIGN_UP: {
      return SignUp;
    }
    case StepType.FINISH: {
      return Finish;
    }
    case StepType.EMPTY_STATE: {
      return EmptyState;
    }
    default: return ErrorScreen;
  }
}
