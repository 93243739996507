import matchSelection from './match-selection';
import StepType from '../data/step-type.enum';

export default function getForwardVisible({
  stepType, stepId, values, content, selectionMatcher = matchSelection,
}) {
  switch (stepType) {
    case StepType.BUTTON_SELECT:
    case StepType.PLAIN_LIST_SELECT:
    case StepType.SEARCH_LIST_SELECT:
    case StepType.NUMBER_LIST_SELECT:
    case StepType.LIST_SELECT:
    case StepType.LIST_SELECT_NUMBER_SEARCH: {
      return selectionMatcher({ stepId, content, values });
    }
    case StepType.CHECKBOX_LIST: {
      return true;
    }
    case StepType.PRIMARY_FORECAST: {
      return true;
    }
    default: return false;
  }
}
