import { call, put } from 'redux-saga/effects';

import ContentAction from './action-type.enum';

export default function* loadContentSaga(contentGetters, stepId, values) {
  const getter = contentGetters.find(x => x.stepId === stepId);
  if (getter) {
    yield put({
      type: ContentAction.CONTENT_CLEAR,
      payload: { stepId },
    });
    let content = null;
    let isError = false;
    try {
      content = yield call(getter.getContent, values);
    } catch(err) {
      isError = true;
    }
    yield put({
      type: ContentAction.CONTENT_SET,
      payload: {
        stepId,
        isError,
        content,
      },
    });
  }
}
