import StepId from '../../step-id.enum';

export default function makeGetMachineTypeAvailable(categories) {
  return function(values) {
    const categoryId = values
      .find(x => x.stepId === StepId.CATEGORY)
      .value
      .id;
    const category = categories.find(x => x.id === categoryId);
    const { machineTypes } = category;
    return machineTypes && machineTypes.length > 0;
  }
}
