import React from 'react';

import './sign-up.css';
import '../styles/step-frame.css';
import '../styles/custom-scrollbar.css';
import '../styles/predictor.css';
import laptop from './laptop.png';
import CustomCheckbox2 from '../custom-checkbox/custom-checkbox-2';

export default function SignUpView({ onSubmit }) {
  function handleSubmit(e) {
    e.preventDefault();
    const email = e.target.email.value;
    onSubmit(email);
  }

  return (
    <div className="step-frame">
      <div className="sign-up step-frame__content custom-scrollbar">
        <div className="sign-up__content">
          <div className="sign-up__content-part sign-up__content-part_image">
            <img
              src={laptop}
              alt="Laptop"
              className="sign-up__image"
            />
          </div>

          <div className="sign-up__content-part sign-up__content-part_text">
            <h1 className="sign-up__heading predictor__heading-1">
              Sie suchen das beste Angebot?
            </h1>
           
            <p
              className="predictor__text"
              style={{ marginBottom: '1.5rem' }}
            >
              Profitieren Sie von unserem umfangreichen Partnernetzwerk, um das passende 
              Angebot für Sie zu finden! Wir leiten Ihre Anfrage gerne kostenlos an unsere Händler weiter,
              um Ihnen attraktive Gebrauchtmaschinenangebote zu präsentieren. 
            </p>

            <p
              className="predictor__text predictor__text_large"
              style={{ marginBottom: '2rem' }}
            >
              Jetzt anfragen
            </p>

            <form onSubmit={handleSubmit}>
              <div style={{ marginBottom: '1rem' }}>
                <i
                  className="far fa-envelope"
                  style={{ marginRight: '.75rem', color: '#bbb' }}
                ></i>
                <input
                  type="email"
                  name="email"
                  placeholder="Email Adresse hier eintragen"
                  className="predictor__input"
                  style={{ width: '15rem' }}
                  required
                />
              </div>

              <div className="sign-up__checkbox-wrapper">
                <CustomCheckbox2 required>
                  <span style={{ fontSize: '.9rem' }}>
                    Ich bin mit der Verwendung meiner E-Mail Adresse zwecks Kontaktaufnahme einverstanden.
                  </span>
                </CustomCheckbox2>
              </div>

              <button
                type="submit"
                className="predictor__button predictor__button_accent"
                style={{ marginBottom: '1rem' }}
              >
                Senden
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
