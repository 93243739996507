import React, { Component } from 'react';

//files
import './help.css';

export default class Help extends Component {
  constructor(props) {
    super(props);
     this.state = {
       paragraphTrimmedLength: [220, 220, 220, 220, 220, 220, 220, 220, 220, 220, 220, 220, 220, 220, 220, 220, 220, 220],
    };
    this.trimParagraph = this.trimParagraph.bind(this);
  }
  trimParagraph = (elementToTrim, from, to) => {
    return elementToTrim.substring(from, to);
  }
  toggleParagraph = (state, stateName, to, i) => {
      let newArr = [...state];
      let oldArray = [...state]; 
      newArr[i] = to;           
      oldArray[i] = 220;
      if (state[i] === 99999) {
        this.setState({
          [stateName]: oldArray,
        })
      } else {
        this.setState({
          [stateName]: newArr,
        })
      }
  }
  render() {
    const data = [
      { heading: 'Wie funktioniert hektor.one?', paragraph: 'hektor.one ist Ihr Experte für gebrauchte Landmaschinen. Über einen kurzen Fragebogen werden wesentliche technische Merkmale geklärt und anschließend wird Ihnen eine erste Preisprognose kostenlos angezeigt. ' },
      { heading: 'Was kostet die Maschinenbewertung?', paragraph: 'Die erste Marktpreisprognose ist für Sie kostenlos. Sollten Sie weitere Maschinen bewerten wollen oder eine detailierte Inspektion wünschen, beraten wir Sie gerne im Anschluss' },
      { heading: 'Was geschieht mit meinen Daten?', paragraph: 'Wir werden Ihre Daten vertraulich behandeln und nur die für den Zweck der Kontaktaufnahme zwischen Hektor.one und Ihnen verwenden. Ebenso würden wir Sie gerne über zukünftige Angebote per E-Mail kontaktieren. Sollten Sie die Löschung Ihrer Daten bei uns wünschen, können Sie sich selbstverständlich hierfür an uns wenden.' },
      { heading: 'Wie lange dauert es, bis ich eine Preisbewertung erhalte?', paragraph: 'Sie erhalten die Marktpreisprognose sofort.' },
      { heading: 'Erhalte ich für eine über hektor.one bestellte Maschine Service beim Handel?', paragraph: 'Die meisten unserer Kundenanfragen werden schlussendlich über den Handel abgewickelt. Somit ist stets ein lokaler direkter Ansprechpartner gegeben und die Art der Abwicklung mit den Handle bleibt im üblichen Model. Aber auch wenn Sie eine Maschine von einem unserer Partner direkt beziehen gilt - Kunden unseres Portals sind beim Handel gerne gesehen.' },
      { heading: 'Wer steckt hinter hektor.one?', paragraph: 'hektor.one ist ein Projekt der Rheinlandmaschinen UG Düsseldorf. Begründet wurde das Projekt durch die Schmiede.one,das Innovationslabor der Grimme Gruppe aus Damme. Wir beschäftigen uns mit neuen Technologien und dem Lösen von Kundenproblemen im Bereich des Maschinenhandels. Erfahren Sie mehr über uns auf www.schmiede.one.' },
      { heading: 'Welche Maschinen werden im Bereich Transport bewertet?', paragraph: 'Für den Bereich Transport und Transporttechnik bieten wir ein breites Spektrum an Lösungen und Fabrikaten. Egal ob Kipper, Abschiebewagen oder Häckseltransportwagen. Bei hektor.one werden Sie fündig! So werden durch unsere Hersteller Einachser, Zweiachser, Dreiachser mit sämtlichen Gut-transportmethoden angeboten. Dreiseitenkipper, Tandenkipper gehören daher genauso zu unserem Produktprogramm wie Muldenkipper, Plattformwagen, Plattformtransportwagen und Ballentransportwagen. Weiterhin bieteten wir über unsere Seite Maschinen von Herstellern für Überladewagen, Hakenlifte, Hakenlift-Abrollkipper, Viehtransporter, Dollys und Tandemdollys. Aber auch Miststreuer oder Lösungen für die Feld- und Materiallogistik gehören zu dieser Produktgruppe.' },
      { heading: 'Führt hektor.one Inspektionen für Gülletechnik aus?', paragraph: 'Auch im Bereich Gülletechnik bieten wir Ihnen eine breite Palette an Herstellern und Maschinen, wie zum Beispiel Einachs-Güllefässer, Zweiachs-Güllefässer, Selbstfahrer. Auch wenn Sie Tandem-Güllewagen oder Tridem-Güllewagen suchen, werden Sie bei uns fündig. Weiterhin werden durch unsere Partnerunternehmen Abschiebetanker, Mobile Feldrandcontainer, Güllegrubber, Scheibengrubber, Schleppschuhverteiler, diverse Verteilsysteme und Anbauten sowie Zubehörteile angeboten.' },
      { heading: 'Welche Maschinen werden im Bereich Kommunal, Hoftechnik und Radler bewertet?', paragraph: 'Für den Bereich Hoftechnik bieten wir ihnen Systeme der Verladetechnik wie Schüttbunker, Transportbänder, Sortiermaschinen, Kistensortierer, Kistensortiermaschinen sowie Mulchsysteme wie Frontmulcher, Seitenmulcher, Kombimulcher und zahlreiche Anbauten wie Aufnahmen, Frontgabeln und Frontladern. Eine weitere wesentliche Produktgruppe in diesem Bereich besteht aus Radladern, Teleskopradladern sowie Frontladern sowie Kleintraktoren, Kompakttraktoren, Kommunaltraktoren, Mähmaschinen, Schmalspurtraktoren sowie Allroundmaschinen.' },
      { heading: 'Welche Maschinen bewerten wir im Bereich Beregnung?', paragraph: 'Auch für die Beregnungstechnik sind über hektor.one alle Lösungen und Systeme verfügbar. Neben Beregnungsmaschinen, Pumpen, Beregnungsanlagen und Beregnungssystemen, MDPE-Rohren und Pumpaggregaten, werden auch Traktorpumpen, Schlauchhaspeln, Düsenwagen und Regner angeboten.' },
      { heading: 'Welche Maschinen gibt es im Bereich Aussaat, Setzen und Legen?', paragraph: 'Maschinen für die Aussaat sind ebenso über uns verfügbar. Hier setzen wir unseren Schwerpunkt auf mechanische Drillmaschinen, pneumatische Drillmaschinen, Bestellkombinationen und Einzelkornsämaschinen beziehungsweise Einzelkornsätechnik. Darüber hinaus sind aber auch Kartoffellegemaschinen, Bandlegemaschinen oder Maschinen der Setztechnik über uns verfügbar.' },
      { heading: 'Für welche Maschinen gibt es im Bereich Bodenbearbeitung Bewertungen?', paragraph: 'Bodenbearbeitung ist einer der Schwerpunktkategorien bei hektor.one . Wir bieten Ihnen ein breites Spektrum im Bereich Pflüge wie Anbaupflüge und Aufsatteldrehpflüge sowie im Bereich Rückverfestigung : Integrierte Packer, Untergrundpacker und Nachlaufwalzen. Aber auch die Saatbettbereitung wird mit Kultureggen, Zinkeneggen, Kreiseleggen und Saatbettkombinationen abgedeckt. Dies betrifft ebenso Neumaschinen für die Stoppelbearbeitung wie Kurzscheibeneggen oder Grubber, Vorgrubber, Fahrgassenlockerer, Vorlockerer. Auch werden Packer, Walzen, Crossskillwalzen und Cambridgewalzen angeboten.' },
      { heading: 'Welche Maschinen gibt es im Bereich Erntetechnik?', paragraph: 'm Bereich der Erntetechnik haben wir uns zunächst auf die Segmente Anbauten sowie Kartoffeltechnik und Gemüsetechnik spezialisiert. Somit sind über hektor.one derzeit Maisgebisse, Rapsgebisse, Häckselwerke und andere Frontanbauten für Mähdrescher und Häcksler verfügbar. Ebenso bieteten wir Ihnen eine breite Palette an Rübenrodern wie etwa gezogene Roder oder Selbstfahrer. Gleiches gilt für den Bereich Kartoffeltechnik - auch hier sind über unser Portal Selbstfahrer als auch gezogene Erntetechnik und Überladeroder verfügbar. Aber auch Krautschläger gehören diesem Segment an.' },
      { heading: 'Welche Maschinen gibt es im Bereich Spritzen und Düngen?', paragraph: 'Über hektor.one sind sowohl Anbaufeldspritzen als auch Anhängespritzen erhätllich. Ebenso bieten wir Ihnen zahlreiche Anbauten, Fasssysteme und Fässer. Sowohl Kunstdüngerstreuer als auch Feststreuer beziehungsweise Festmiststreuer ( Miststreuer ) sind ebenso über uns verfügbar. Wir arbeiten mit den Spezialisten in diesem Segment zusammen und können Ihnen somit sowohl Anbaustreuer, Anbaudüngerstreuer als auch gezogene Düngerstreuer mit unterschiedlichsten Verteilsystem bieten.' },
      { heading: 'Wie sieht es bei der Grünlandtechnik aus?', paragraph: 'Wir bieten Ihnen Grünlandeggen, Grünlandstriegel, Mähwerke wie Scheibenmähwerke, Trommelmähwerke aber auch sämtliche Wender wie Kreiselzettwender, Kreiselschwader, Einkreiselschwader, Zweikreiselschwader, Vierkreiselschwader, Sechskreiselschwader. Darüber hinaus werden auch Häckselsysteme, Häckselwagen und Transportwagen etwa für Ballen angeboten.' },
      { heading: 'Welche Maschinen werden im Bereich Fütterungstechnik bewertet?', paragraph: 'Sie erhalten bei uns passende Angebote für die Bereiche Fütterungstechnik, Entnahmetechnik und Einstreutechnik. Insbesondere umfasst dies Futtermischwagen, wie z.b. stationäre Futtermischwagen, selbstfahrende Futtermischwagen, Selbstlader, Fremdbefüller sowie andere Arten von vertikalen Futtermischwagen. Sie erhalten bei uns somit Angebote für 1-Schneckenmischer, 2-Schneckenmischer sowie auch 3-Schneckenmischer. Für die Entnahmetechnik sowie Einstreutechnik erhalten Sie Angebote für Siloblockschneider, Schneidzangen, Strohgebläse sowie Einstreugeräte. Auch für Biogasanlagen bieten wir Ihnen unterschiedlichste Technik wie Feststoffdosierer und Mischtechnik.' },
     
  ];
    return (
    <div className="row">
      <div className="helpContainer" id="help">
        <div className="helpWrapper">
          <div className="hilfeContent" /* style={{ display: (this.props.isHilfeVisible) ? 'block' : 'none' }} */>
            <h2 style={{ color: '#ffb232' }}>hektor.one <span>Hilfecenter</span></h2>
            {data.map((element, i) => 
              <div key={i} className="helpElement">
                <h3>{element.heading}</h3>
                <p>{this.trimParagraph(element.paragraph, 0, this.state.paragraphTrimmedLength[i])}
                  <button 
                    className="weiterButton" 
                    onClick={() => this.toggleParagraph(this.state.paragraphTrimmedLength, 'paragraphTrimmedLength', 99999, i)}
                  >
                  {(this.state.paragraphTrimmedLength[i] >= 99999) ? 'Schließen' : '...Weiterlesen'}
                  </button>
                </p>
              </div>)
            }
          </div>
        </div>
      </div>
    </div>
    );
  }
}
