import { call, fork, put } from 'redux-saga/effects';

import setStepSaga from './set-step-saga';
import stepSelectSaga from './step-select-saga';
import stepForwardSaga from './step-forward-saga';
import stepBackSaga from './step-back-saga';
import StepAction from '../step/action-type.enum';
import restartSaga from './restart-saga';

function* predictorSaga(predictorParams) {
  const { steps } = predictorParams;
  yield put({
    type: StepAction.NUM_SET,
    payload: {
      num: steps.length,
    },
  });
  yield call(setStepSaga, predictorParams, 0);
  yield fork(stepSelectSaga, predictorParams);
  yield fork(stepForwardSaga, predictorParams);
  yield fork(stepBackSaga, predictorParams);
  yield fork(restartSaga, predictorParams);
}

export default predictorSaga;
