export default function matchSelection({ stepId, content, values }) {
  const valueRecord = values.find(x => x.stepId === stepId);
  if (!valueRecord) {
    return false;
  }
  const { id } = valueRecord.value;
  const contentRecord = content.find(x => x.stepId === stepId);
  const stepContent = contentRecord.content;
  const option = stepContent.find(x => x.id === id);
  return !!option;
}
