import React from "react";

//scroll
import { Link as ScrollLink } from "react-scroll";

//router
import { Link } from "react-router-dom";

//styles
import "./banner.css";

export default class BannerAbout extends React.Component {
  render() {
    let content;
    if (!this.props.isButtonDisabled) {
      if (this.props.externalLink) {
        content = 
        <span>
          <Link
            to={this.props.externalLink}
            title={this.props.buttonText}
            className="hecktorYellow"
          >
            <span>{this.props.buttonText}</span>
          </Link>
        </span>;
      } else {
        content =
        <span>
          <ScrollLink
            title={this.props.buttonText}
            className="hecktorYellow"
            to="contactForm"
            spy={true}
            smooth={true}
            duration={1000}
          >
            <span>{this.props.buttonText}</span>
          </ScrollLink>
        </span>;
      }
    }
    return (
      <div
        className="bannerContainer"
        id={this.props.customId}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: this.props.customHeight || "100vh",
          minHeight: this.props.customMinHeight || "480px", //for landscape view of mobile devices
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundImage: `url(${this.props.backgroundImage})`
        }}
      >
        <div className="contentrWrapper">
          <h1>{this.props.headline}</h1>
          <p>{this.props.paragraph}</p>
          <span>
            {content}
          </span>
        </div>
      </div>
    );
  }
}