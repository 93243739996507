import StepAction from './action-type.enum';

const initialState = {
  index: null,
  id: null,
  type: null,
  heading: null,
  searchPlaceholder: null,
  opts: null,
  num: null,
};

export default function stepReducer(state = initialState, action) {
  switch (action.type) {
    case StepAction.STEP_SET: {
      const { index, id, type, heading, searchPlaceholder, opts } = action.payload;
      return Object.assign({}, state, {
        index, id, type, heading, searchPlaceholder, opts,
      });
    }
    case StepAction.NUM_SET: {
      const { num } = action.payload;
      return Object.assign({}, state, { num });
    }
    default: return state;
  }
}
