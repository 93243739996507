import React from 'react';

import './checkbox-list.css';
import '../styles/custom-scrollbar.css';
import '../styles/step-frame.css';
import CustomCheckbox from '../custom-checkbox';
import parseOptions from '../../lib/parse-options';

function CheckboxOption({ option, checked, onChange }) {
  return (
    <li
      data-testid="checkbox-list__option"
      className="checkbox-list__list-item"
    >
      <CustomCheckbox
        label={option.label}
        checked={checked}
        onChange={ e => onChange(option) }
      />
    </li>
  );
}

export default function CheckboxListView({
  stepId, content, values, onChange,
}) {
  function handleChange(option) {
    const record = values.find(x => x.stepId === stepId)
    const optionValues = record ? record.value : [];
    const { id } = option;
    let newValues = [];
    if (optionValues.includes(option)) {
      newValues = optionValues.filter(x => x.id !== id);
    } else {
      newValues = optionValues;
      newValues.push(option);
    }
    onChange(stepId, newValues);
  }

  function renderOptions() {
    const options = parseOptions(stepId, content);
    const record = values.find(x => x.stepId === stepId)
    const optionValues = record ? record.value : [];
    return options
      .sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      })
      .map((x, i) => {
      const checked = !!optionValues.find(y => y.id === x.id);
      return <CheckboxOption
        key={i}
        option={x}
        checked={checked}
        onChange={handleChange}
      />
    });
  }

  return (
    <div className="step-frame">
      <div className="checkbox-list step-frame__content">
        <ul className="checkbox-list__list custom-scrollbar">
          { renderOptions() }
        </ul>
      </div>
    </div>
  );
}
