/**
 * Production tires conditions data.
 */

const tiresConditions = [
  { id: 'like-new', label: 'Wie "neu" (80% - 100%)' },
  { id: 'good', label: 'Gut (50% - 80%)' },
  { id: 'bad', label: 'Schlecht (20% - 50%)' },
  { id: 'very-bad', label: 'Sehr schlecht (0% - 20%)' },
];

export default tiresConditions;
