import React, { useState, useEffect } from 'react';

import './list-select.css';
import '../styles/custom-scrollbar.css';
import '../styles/step-frame.css';
import parseOptions from '../../lib/parse-options';

function getActiveOptionId(stepId, values) {
  if (!values) {
    return null;
  }
  const valueRecord = values.find(x => x.stepId === stepId);
  if (!valueRecord) {
    return null;
  }
  const { value } = valueRecord;
  return value.id;
}

function ListSelectOption({ option, activeId, onClick }) {
  const isActive = option.id === activeId
  const dataActive = isActive ? 'true' : 'false';
  const className = isActive ?
    'list-select__option_active' :
    'list-select__option';
  return (
    <li
      data-testid="list-select__option"
      data-active={dataActive}
      className={className}
      onClick={onClick}
    >
      { option.label }
    </li>
  );
}

function makeTextSearchPredicate(query) {
  return x => {
    if (!query) {
      return x;
    }
    const pattern = new RegExp(query, 'i');
    return x.label.toString().match(pattern);
  };
}

function makeNumberSearchPredicate(query) {
  return x => {
    if (!query || Number.isNaN(query)) {
      return x;
    }
    const { min, max } = x.opts;
    const matchesMin = query >= min;
    const matchesMax = max ? query <= max : true;
    return matchesMin && matchesMax;
  };
}

/**
 * ListSelect view component.
 * @param {*} param0 
 */
export default function ListSelectView({
  stepId,
  content,
  values,
  //stepOpts,
  searchEnabled = false,
  searchPlaceholder = 'Suche',
  isNumberSearch = false,
  onSelect,
}) {
  const [query, setQuery] = useState('');

  // resetting `query` for new step
  useEffect(() => setQuery(''), [stepId]);

  // scrolling list to top on init
  useEffect(() => {
    const list = document.querySelector('.list-select__list');
    list.scrollTo && list.scrollTo(0, 0);
  }, [stepId]);

  function getSearchPredicate() {
    if (isNumberSearch) {
      return makeNumberSearchPredicate(query);
    }
    return makeTextSearchPredicate(query);
  }

  function renderOptions() {
    const options = parseOptions(stepId, content);
    const activeId = getActiveOptionId(stepId, values);
    return options
      .filter(getSearchPredicate())
      .map((x, i) => (
        <ListSelectOption
          key={i}
          option={x}
          activeId={activeId}
          onClick={ e => onSelect(stepId, x) }
        />
      ));
  }

  function renderSearchBar() {
    if (searchEnabled) {
      return (
        <div className="list-select__search">
          <input
            type="text"
            className="list-select__search-input"
            placeholder={searchPlaceholder}
            data-testid="list-select__search-input"
            value={query}
            onChange={ e => setQuery(e.target.value) }
          />
          <div className="list-select__search-icon">
            <i className="fas fa-search"></i>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="step-frame">
      <div className="list-select step-frame__content">
        { renderSearchBar() }
        <ul className="list-select__list custom-scrollbar">
          { renderOptions() }
        </ul>
      </div>
    </div>
  );
}
