import { connect } from 'react-redux';

import FinishView from './view';
import PredictorAction from '../../modules/predictor/action-type.enum';

function mapStateToProps(state) {
  return {
    content: state.predictor.content,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onRestart: () => dispatch({ type: PredictorAction.RESTART }),
  };
}

const Finish = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FinishView);

export default Finish;
