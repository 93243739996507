import { connect } from 'react-redux';

import SearchListSelectView from './view';

function mapStateToProps(state) {
  return {
    stepId: state.predictor.step.id,
    content: state.predictor.content,
    searchPlaceholder: state.predictor.step.searchPlaceholder,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

const SearchListSelect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchListSelectView);

export default SearchListSelect;
