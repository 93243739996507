import { call, select, take } from 'redux-saga/effects';

import PredictorAction from './action-type.enum';
import { valueSelector } from './selectors';
import getStepSaga from '../step/get-step-saga';
import stepSetSaga from '../step/step-set-saga';
import loadContentSaga from '../content/load-content-saga';

export default function* stepForwardSaga(predictorParams) {
  while (true) {
    yield take(PredictorAction.STEP_FORWARD);

    // getting registered values
    const values = yield select(valueSelector);

    // getting next available step
    const { contentGetters } = predictorParams;
    const stepData = yield call(getStepSaga, predictorParams, values);
    const { step, index } = stepData;
    const { id } = step;

    // setting step
    yield call(stepSetSaga, step, index);

    // loading content of the step
    yield call(loadContentSaga, contentGetters, id, values);
  }
}
