const CategoryId = {
  TRAKTOR: 'Traktoren', // tractor
  RADLADER: 'Radlader', // wheel loader
  SAE_PFLANZTECHNIK : 'Sae-Pflanztechnik',  // sowing-planting
  DUENGER_PFLANZENSCHUTZ: 'Duengetechnik-Pflanzenschutzspritzen',  // crop & plant protection
  MAEHDRESCHER: 'Maehdrescher',  // combine

  // Bodenbearbeitung (Tillage)
  PFLUG: 'Pfluege',
  GRUBBER: 'Grubber', // cultivator
  KREISELEGGE: 'Kreiseleggen',  // rotary harrow
  SCHEIBENEGGE: 'Scheibeneggen',  // disk harrow
  FRAESE: 'Fraesen',  // mill
  MULCHER: 'Mulcher', // mulcher

  GUELLETECHNIK: 'Guelle-Dungtechnik',  // slurry

  // Transport
  ABSCHIEBEWAGEN: 'Abschiebewagen', // pusher
  KIPPER: 'Kipper', // Tipper
  PLATTFORMWAGEN: 'Plattformwagen', // platform-trailer
  SILIERWAGEN: 'Lade-Silierwagen-Siliertechnik', // sillage

  // Erntetechnik diverses (harvesting)
  ERNTETECHNIK_DIVERSES: 'Erntetechnik-Diverses', // harvesting tech (internal use)
  RUEBENRODER: 'Ruebenerntetechnik',  // turnip harvester
  KARTOFFELRODER: 'Kartoffelroder', // potato harvester

  // Gruenlandtechnik (grassland)
  SCHWADER: 'Schwader', // rake

  FUETTERUNGSTECHNIK: 'Fuetterungstechnik', // feeding
  BEREGNUNGSTECHNIK: 'Beregnungs-Drainagetechnik',  // irrigation
};

export default CategoryId;
