/**
 * Production working hour values data.
 */

const hours = [
  {
    id: '0 - 500',
    label: '0 - 500',
    opts: {
      min: 0,
      max: 500,
    },
  },
];

// incrementing 500
for (let i = 500; i < 8000; i += 500) {
  const min = i + 1;
  const max = i + 500;
  const label = `${min} - ${max}`;
  hours.push({
    id: label,
    label,
    opts: {
      min,
      max,
    },
  });
}

// incrementing 1000
hours.push({
  id: '8001 - 9000',
  label: '8001 - 9000',
  opts: {
    min: 8001,
    max: 9000,
  },
});
hours.push({
  id: '9001 - 10000',
  label: '9001 - 10000',
  opts: {
    min: 9001,
    max: 10000,
  },
});

// incrementing 2000
for (let i = 10000; i < 24000; i += 2000) {
  const min = i + 1;
  const max = i + 2000;
  const label = `${min} - ${max}`;
  hours.push({
    id: label,
    label,
    opts: {
      min,
      max,
    },
  });
}

hours.push({
  id: '24001 - ...',
  label: '24001 - ...',
  opts: {
    min: 24001,
  }
});

/*const hours0 = [
  { id: 0, label: '0 Stunden', },
  { id: 100, label: '100 Stunden', },
  { id: 200, label: '200 Stunden', },
  { id: 300, label: '300 Stunden', },
  { id: 400, label: '400 Stunden', },
];*/

export default hours;
