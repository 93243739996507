const StepType = {
  BUTTON_SELECT: 'BUTTON_SELECT',
  PLAIN_LIST_SELECT: 'PLAIN_LIST_SELECT',
  SEARCH_LIST_SELECT: 'SEARCH_LIST_SELECT',
  NUMBER_LIST_SELECT: 'NUMBER_LIST_SELECT',
  LIST_SELECT_NUMBER_SEARCH: 'LIST_SELECT_NUMBER_SEARCH', // deprecated
  CHECKBOX_LIST: 'CHECKBOX_LIST',
  FORECAST: 'FORECAST',
  SIGN_UP: 'SIGN_UP',
  FINISH: 'FINISH',
  EMPTY_STATE: 'EMPTY_STATE',
  // for testing purposes
  LIST_SELECT: 'LIST_SELECT', // deprecated
  PRIMARY_FORECAST: 'PRIMARY_FORECAST', // deprecated
  TEST: 'TEST',
  TEST_0: 'TEST_0',
};

export default StepType;
