
import React from 'react';

//styles
import "./about.css";

//media

function infoText() {
    return (
      <div className="infoWrapper">
        <div className="contentContainer">
      
          <h1>
            <span className="light">Wir begleiten Ihre Betriebsauflösung und</span> Restrukturierung.
          </h1>
          <div className="paragraphs">
            <p>
              Sie stehen vor d
            </p>
          </div>
          <div className="paragraphs">
            <h5>Gebrauchtmaschinen zu Höchstpreisen vermarkten</h5>
            <p>
              Unser neuer Service ermöglicht es Ihnen, Ihre Maschine durch unsere Experten inspizieren zu lassen und
              anschließend ein hochwertiges Inserat für Sie zu erstellen, welches auf den großen Portalen für Sie platziert wird.
            </p>
            <p>
              Wir kümmern uns um die Kontaktaufnahme mit Interessenten und filtern unseriöse Anfragen heraus.
              Ebenso führen wir die Verhandlungen, aber schlussendlich entscheiden Sie, ob Sie ein Angebot annehmen.
            </p>
          </div>
          <div className="paragraphs">
            <h5>Provision nur im Erfolgsfall</h5>
            <p>
              Für unsere Vermittlung vereinbaren wir eine 1,75% Provision. Somit sind wir bestrebt, den höchstmöglichen
              Erlös für Sie zu realisieren. Auch die weitere Abwicklung übernehmen wir für Sie auf Wunsch.
            </p>
            <p className="emailschreiben">
              Möchten Sie mehr erfahren?{' '}
              <a href="mailto: info@hektor.one" title="Schreiben Sie uns eine Nachricht!">
                Schreiben Sie uns eine Nachricht!
              </a>
            </p>
          </div>
         
        </div>
        <div>&nbsp;</div>
      </div>
    );
  }

export default infoText;
