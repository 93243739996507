
import React from 'react';

//styles
import "./about.css";

//media

function infoText() {
    return (
      <div className="infoWrapper">
        <div className="contentContainer">
          <h1>
            <span className="light">Landmaschinen sind Unsere </span>Leidenschaft
          </h1>
          <div className="paragraphs">
            <p>
            Hektor.one wurde im Herbst 2018 erstmalig als Angebotsportal für Neumaschinen getestet. In 3 Monaten konnten wir über 150.000 Besucher auf unserer Seite verzeichnen und mehrere tausend Angebote über unsere Partner ausliefern. Ein wesentlicher Baustein des Neumaschinenerwerbs konnte damals jedoch nicht direkt durch uns adressiert werden - die Abwicklung der Gebrauchtmaschine. Indem das neue Hektor.one nun genau an diesem Punkt ansetzt, wollen wir den digitalen Maschinenhandel über die gesamte Strecke hinweg begleiten.
            </p>
          </div>
          <div className="paragraphs">
            <h5>Maschinen zustandsgerecht bewerten</h5>
            <p>
            Unser neuer Service ermöglicht es Ihnen, Ihre Maschine automatisiert bewerten zu lassen und anschließend auf Wunsch weitergehende Unterstützung durch unser Händlernetzwerk zu erhalten.


            </p>
          </div>
          <div className="paragraphs">
           
            <p className="emailschreiben">
              Möchten Sie mehr erfahren?{' '}
              <a href="mailto: info@hektor.one" title="Schreiben Sie uns eine Nachricht!">
                Schreiben Sie uns eine Nachricht!
              </a>
            </p>
          </div>
         
        </div>
        <div>&nbsp;</div>
      </div>
    );
  }

export default infoText;
