import React from 'react';

import styles from './empty-state.module.css';
import '../styles/step-frame.css';
import '../styles/custom-scrollbar.css';
import '../styles/predictor.css';
import CustomCheckbox2 from '../custom-checkbox/custom-checkbox-2';


export default function EmptyStateView({ onSubmit }) {
  function handleSubmit(e) {
    e.preventDefault();
    const email = e.target.email.value;
    onSubmit(email);
  }

  return (
    <div className="step-frame">
      <div className={`${styles.frameContent} step-frame__content custom-scrollbar`}>
        <div style={{ textAlign: 'center' }}>
          <h1 className={`${styles.heading} predictor__heading-1`}>
            Automatische Vorhersage noch nicht verfügbar.
          </h1>
          
          <p
            className={`${styles.text} predictor__text"`}
            style={{
              maxWidth: '35rem',
              margin: '0 auto 1.5rem'
            }}
          >
            Wir werden uns gerne mit Ihnen in Verbindung setzen, um auf der 
            Grundlage Ihrer Informationen eine manuelle Preisbewertung vorzunehmen.
          </p>

          <p
            className="predictor__text predictor__text_large"
            style={{ marginBottom: '2rem' }}
          >
            Bitte teilen Sie uns Ihre E-Mail-Adresse mit.
          </p>

          <form onSubmit={handleSubmit}>
            <div style={{ marginBottom: '1rem' }}>
              <i
                className="far fa-envelope"
                style={{ marginRight: '.75rem', color: '#bbb' }}
              ></i>
              <input
                type="email"
                name="email"
                placeholder="Email Adresse"
                className="predictor__input"
                style={{ width: '15rem' }}
                required
              />
            </div>

            <div style={{
              maxWidth: '23rem',
              margin: '0 auto 1.5rem',
            }}>
              <CustomCheckbox2 required>
                <span style={{ fontSize: '.9rem' }}>
                  Ich bin mit der Verwendung meiner E-Mail Adresse 
                  zwecks Kontaktaufnahme einverstanden.
                </span>
              </CustomCheckbox2>
            </div>

            <button
              type="submit"
              className="predictor__button predictor__button_accent"
              style={{ marginBottom: '1.5rem' }}
            >
              Kontaktiere mich
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
