import React from 'react';

import StepId from '../../data/step-id.enum';

export default function PrimaryForecastView({ values, onForward }) {
  function getValueByStepId(stepId) {
    const valuesItem = values.find(x => x.stepId === stepId);
    return valuesItem ? valuesItem.value.label : null;
  }

  // for NUMBER_INPUT steps
  /*function getNumberValueByStepId(stepId) {
    const valueRecord = values.find(x => x.stepId === stepId);
    return valueRecord ? valueRecord.value : null;
  }*/

  const category = getValueByStepId(StepId.CATEGORY);
  const machineType = getValueByStepId(StepId.MACHINE_TYPE);
  const manufacturer = getValueByStepId(StepId.MANUFACTURER);
  const model = getValueByStepId(StepId.MODEL);
  const power = getValueByStepId(StepId.POWER);
  const year = getValueByStepId(StepId.YEAR);
  const hour = getValueByStepId(StepId.HOUR);
  const hectare = getValueByStepId(StepId.HECTARE);
  const condition = getValueByStepId(StepId.CONDITION);
  const tiresCondition = getValueByStepId(StepId.TIRES_CONDITION);

  function renderAddOns() {
    const addOnsRecord = values.find(x => x.stepId === StepId.ADD_ONS);
    if (addOnsRecord) {
      const addOns = addOnsRecord.value.map(x => x.label);
      return addOns.join(', ');
    }
  }

  return (
    <div data-testid="primary-forecast">
      <h2>PrimaryForecastView</h2>

      <ul>
        <li>
          <span>Category:</span>
          {' '}
          <b>{ category }</b>
        </li>
        <li>
          <span>Machine Type:</span>
          {' '}
          <b>{ machineType }</b>
        </li>
        <li>
          <span>Manufacturer:</span>
          {' '}
          <b>{ manufacturer }</b>
        </li>
        <li>
          <span>Model:</span>
          {' '}
          <b>{ model }</b>
        </li>
        <li>
          <span>Power:</span>
          {' '}
          <b>{ power }</b>
        </li>
        <li>
          <span>Year:</span>
          {' '}
          <b>{ year }</b>
        </li>
        <li>
          <span>Hours:</span>
          {' '}
          <b>{ hour }</b>
        </li>
        <li>
          <span>Hectares:</span>
          {' '}
          <b>{ hectare }</b>
        </li>
        <li>
          <span>Condition:</span>
          {' '}
          <b>{ condition }</b>
        </li>
        <li>
          <span>Tires Condition:</span>
          {' '}
          <b>{ tiresCondition }</b>
        </li>
        <li>
          <span>Add-Ons:</span>
          {' '}
          <b>{ renderAddOns() }</b>
        </li>
      </ul>

      <div className="mt-4">
        <button
          data-testid="primary-forecast__refined-button"
          onClick={ e => onForward() }
        >
          See Forecast
        </button>
      </div>
    </div>
  );
}
