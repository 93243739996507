import React, { useEffect } from 'react';
import Rellax from 'rellax';
import { Link } from 'react-router-dom';

import './advantages.scss';
import traktor from './traktor-main.png';

export default function Advantages() {
  useEffect(() => {
    // init parallax
    new Rellax('#parallaxImage', {
      center: true,
    });
  }, []);
  return (
    <section>
      <section id="traktor" className="container-fluid position-relative">
        <div className="container text-primary">
          <div className="responsive-h1 mt-4 mt-sm-5 ml-2">
            <span className="font-weight-light">Landtechnik </span>
            <span className="font-weight-bold">fachgerecht bewertet</span>
          </div>
          <div className="row mt-4">
            <div className="col-12 col-md-6 mb-3 mb-md-5 pb-md-4 pb-lg-5">
              <ul id="checkList" className="font-weight-light">
                <li>Kostenlose Erstbewertung</li>
                <li>Sofortiges Ergebnis</li>
                <li>Bequeme Online-Abfrage</li>
                <li>Für Ankauf und Verkauf geeignet</li>
                <li>Aktuell für Traktoren automatisiert verfügbar</li>
                <li>Manuelle Bewertung von Bodenbearbeitung uvm.</li>
                <li>Von Mechanikern für Land- und Baumaschinen für Sie </li>
              </ul>
            </div>
            <div className="col-12 col-md-6 d-flex align-items-end">
              <img 
              id="parallaxImage" 
              className="w-100" 
              data-rellax-speed="2" 
              src={ traktor }
              alt="tractor parallax" />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-primary py-3 py-md-5">
          <div className="container text-center py-5 position-relative">
            <div className="responsive-h2 text-white font-weight-900">
              Auf der Suche nach einer neuen Landmaschine?
            </div>
            <div className="row mt-4">
              <div className="col-10 col-sm-8 col-xl-6 mx-auto">
                <span className="text-white font-weight-light">
                  Im Anschluss an unseren Bewertungsprozess haben Sie die Möglichkeit, auf den umfangreichen
                  Maschinenbestand unseres Händlernetzwerks zuzugreifen.
                </span>
              </div>
            </div>
            <div className="mt-5 mb-4">
              <Link to="/bewertung" className="btn btn-accent">
                kostenlos bewerten
              </Link>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}
