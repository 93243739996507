import React from 'react';

import './video.css';

export default function Video() {
  return (
    <section id="video" className="bg-accent pb-3 pb-md-4 pb-lg-5">
      <div className="container pt-3 pt-sm-0 mt-sm-n5">
        <div className="position-relative">
          <div className="video-container">
            <iframe
              width="853"
              height="480"
              src="https://www.youtube.com/embed/lTPwWa5_ZNk"
              frameBorder="0"
              allowFullScreen
              title="hektor intro video
                "
            ></iframe>
          </div>
          <div id="videoLabel" className="bg-primary px-3 px-sm-4 px-md-5">
            <div className="corner"></div>
            <span className="responsive-h2 text-white text-nowrap font-weight-light">Wie funktioniert</span>
          </div>
        </div>
        <div className="text-center mt-4">
          <span className="responsive-h2 text-white font-weight-700">Maschinenbewertung mit uns?</span>
        </div>
      </div>
    </section>
  );
}
