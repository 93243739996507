import React from 'react';

import './footer.css';
import '../styles/predictor.css';
import getBackVisible from '../../lib/get-back-visible';
import getForwardVisible from '../../lib/get-forward-visible';

/**
 * WARNING:
 * Most of the code of this component has been barely copied
 * from the `Navbar` component.
 * The `Footer` component functionality has NOT been covered with tests.
 */

export default function FooterView({
  index, stepId, stepType, content, values, onForward, onBack,
}) {
  const isBackVisible = getBackVisible({ index, stepId });
  const isForwardVisible = getForwardVisible({ 
    stepType, stepId, values, content,
  });

  function renderBackButton() {
    if (!isBackVisible) {
      return null;
    }
    return (
      <button
        className="predictor__button-link"
        onClick={ e => onBack() }
      >
        <i className="fas fa-chevron-left"></i>
        <span className="ml-2">Zurück</span>
      </button>
    );
  }

  function renderForwardButton() {
    if (!isForwardVisible) {
      return null;
    }
    return (
      <button
        className="predictor__button-link"
        onClick={ e => onForward() }
      >
        <span className="mr-2">Weiter</span>
        <i className="fas fa-chevron-right"></i>
      </button>
    );
  }

  function renderBody() {
    return (
      <footer className="predictor-footer">
        <div style={{ width: '50%' }}>
          { renderBackButton() }
        </div>
        <div style={{ width: '50%', textAlign: 'right' }}>
          { renderForwardButton() }
        </div>
      </footer>
    );
  }

  return (isBackVisible || isForwardVisible) && renderBody();
}
