import React from 'react';

import traktor from './traktor.svg';

/**
 * ButtonSelect option component.
 * @param {*} param0 
 */
export default function ButtonSelectOption({ option, activeId, onClick }) {
  const isActive = option.id === activeId;
  const dataActive = isActive ? 'true' : 'false';
  const className = isActive ?
    'button-select__option_active' :
    'button-select__option';
  const image = option.image || traktor;
  return (
    <div
      data-testid="button-select__option"
      className={className}
      data-active={dataActive}
      onClick={onClick}
    >
      <div className="button-select__option-image-wrapper">
        <img
          src={image}
          alt={option.label}
          className="button-select__option-image"
        />
      </div>
      <div className="button-select__option-label">
        { option.label }
      </div>
    </div>
  );
}
