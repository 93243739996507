import { call, put, take } from 'redux-saga/effects';

import PredictorAction from './action-type.enum';
import ValueAction from '../values/action-type.enum';
import ContentAction from '../content/action-type.enum';
import setStepSaga from './set-step-saga';

export default function* restartSaga(predictorParams) {
  while (true) {
    yield take(PredictorAction.RESTART);
    yield put({ type: ValueAction.CLEAR_ALL });
    yield put({ type: ContentAction.CONTENT_CLEAR_ALL });
    yield call(setStepSaga, predictorParams, 0);
  }
}
