import { connect } from 'react-redux';

import PrimaryForecastView from './view';
import PredictorAction from '../../modules/predictor/action-type.enum';

function mapStateToProps(state) {
  return {
    values: state.predictor.values,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onForward: () => dispatch({ type: PredictorAction.STEP_FORWARD }),
  };
}

const PrimaryForecast = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrimaryForecastView);

export default PrimaryForecast;
