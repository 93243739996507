
import React from 'react';
import Iframe from 'react-iframe'

//styles
import "./about.css";

//media

function infoText() {
    return (
      <div className="infoWrapper">
        <div className="contentContainer">
        
          <h1>
            <span className="light">Unsere aktuellen </span>Vermittlungsaufträge.
          </h1>

             <div className="paragraphs">
            <p>
              Hektor.one vertritt die Eigentümer der nachfolgenden Maschinen in der Vermarktung.
              Möchten auch Sie Ihre Landmaschine über hektor.one verkaufen lassen?
            </p>
          </div>

            <p className="emailschreiben">
            
              <a href="https://hektorone.typeform.com/to/LueTuT" title="Anfrage starten">
                Füllen Sie jetzt unser kostenloses Anfrageformular aus
              </a>
            </p>
       

            <Iframe url="https://www.traktorpool.de/hektor-one/"
            width="100%"
            height="1000px"
            id="myId"
            className="myClassname"
            display="initial"
            position="relative"
        />
         
         
        </div>
        
      </div>
    );
  }

export default infoText;
