const apiKey = process.env.REACT_APP_CONTACT_API_KEY ||
  'BDA1D693D38A86C69F41357683FF3';  // HARDCODE

const localApi = process.env.REACT_APP_API_URL ||
  "https://fich10ugy3.execute-api.eu-central-1.amazonaws.com/dev";
const deployedApi =
  process.env.REACT_APP_API_URL ||
  "https://fich10ugy3.execute-api.eu-central-1.amazonaws.com/dev";


/**
 * @deprecated
 */
export const CONTACT_API_URL =
  process.env.NODE_ENV !== "production" ? localApi : deployedApi;


async function handleErrors(response) {
  if (!response.ok) {
    const statusText = await response.text();
    throw Error(statusText);
  }
  return response;
}


/**
 * 
 * @deprecated
 */
export default async function callContactApi(endpoint, method = "get", body) {
  try {
    let res = await fetch(`${CONTACT_API_URL}/${endpoint}`, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apiKey
      },
      method,
      body: JSON.stringify(body)
    });
    res = await handleErrors(res);
    return await res.json();
  } catch (e) {
    throw e;  // the error will be caught by saga
  }
}
