import { combineReducers } from 'redux';

import step from '../step/reducer';
import content from '../content/reducer';
import values from '../values/reducer';

const predictorReducer = combineReducers({
  step,
  content,
  values,
});

export default predictorReducer;
