import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Footer from '../footer/Footer';
import ShiftableNavbar from '../navbar/ShiftableNavbar';

//medi
import Close from '../../img/icons/close_orange.svg';

export default class Imprint extends Component {
  render() {
    return (
      <div>
        <ShiftableNavbar />
        <div className={"subpageBody"}>
          <div className={"tempSpacer"}>&nbsp;</div>
            <div className="subpageContainer">
              <div className="impressumContent">
                <h3>Impressum</h3>
                <p className="impressumHeading">
                  <span>Rheinlandmaschinen UG</span>
                  <span className="impressumOneLine">Rather Straße 25</span>
                  <span className="impressumOneLine">40476 Düsseldorf</span>
                  <span className="impressumOneLine">Telefon 0151 112 075 39</span>
                  <span className="impressumOneLine">E-Mail: info@hektor.one</span>
                  <span className="impressumOneLine">Internet: www.hektor.one</span>
                </p>
                <p className="impressumHeading">Umsatzsteuer-Identifikationsnummer:<span className="impressumOneLine">Wird zugeteilt Rechtsform: Gesellschaft mit beschränkter Haftung, Sitz Damme, Amtsgericht Oldenburg HR A 205333 Persönliche Gesellschafterin: Schmiede.ONE Beteiligungs GmbH, Sitz Damme, Amtsgericht Oldenburg HR B 211812.</span></p>
                <p>
                  <span className="impressumHeading">Geschäftsführer:</span>
                  <span className="impressumOneLine">Herr Marten Telake</span>
                </p>
              </div>
              <div className="impressumClose"><Link to="../" title="home"><img src={Close} alt="close" title="close" /></Link></div>
            </div>
        </div>
        <Footer />
      </div>
    );
  }
};