import StepId from '../../step-id.enum';

/**
 * Checks whether current machine type (the one from `values`)
 * belongs to current category. 
 * If it does, both the `categoryId` and `machineTypeId` are 
 * returned within and object.
 * Otherwise, only `{ categoryId }` is returned.
 * @param {*} categories 
 * @param {*} values 
 */
export default function alignCategoryWithType(categories, values) {
  const categoryId = values
    .find(x => x.stepId === StepId.CATEGORY)
    .value
    .id;

  const machineTypeRecord = values
    .find(x => x.stepId === StepId.MACHINE_TYPE);
  if (!machineTypeRecord) {
    return { categoryId };
  }

  const category = categories.find(x => x.id === categoryId);
  const { machineTypes } = category;
  if (!machineTypes) {
    return { categoryId };
  }

  const _id = machineTypeRecord.value.id;
  const machineType = machineTypes.find(x => x.id === _id);
  if (!machineType) {
    return { categoryId };
  }

  const machineTypeId = machineTypeRecord.value.id
  return { categoryId, machineTypeId };
}
