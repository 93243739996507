import StepId from '../step-id.enum';
import makeGetMachineTypeAvailable
  from '../getters/machine-types/make-get-available';
//import makeGetAddOns from '../getters/add-ons/make-get-add-ons';  // old version

import data from './data';
import categories from './categories';
//import allBrands from './brands/json-merged';  // JSON-based, old version
//import addOns from './add-ons/add-ons.json';  // JSON-based, old version
import addOns from './add-ons/data.json';  // next version
//import makeGetBrands from '../getters/brands/make-get-brands';  // TODO: purge
import alignCategoryWithType from '../getters/helpers/align-category-with-type';
import CategoryId from './categories/category-id.enum';

/**
 * Defines whether machine category has an engine.
 */
function withMotorOnly(values) {
  const machineTypesWithMotor = [
    CategoryId.TRAKTOR,
    CategoryId.RADLADER,
    CategoryId.MAEHDRESCHER,
    CategoryId.ERNTETECHNIK_DIVERSES,
  ];
  const { categoryId } = alignCategoryWithType(categories, values);
  return machineTypesWithMotor.includes(categoryId);
}

/**
 * Production step availability getters map.
 */
const availabilities = [
  {
    stepId: StepId.MACHINE_TYPE,
    getAvailable: makeGetMachineTypeAvailable(categories),
  },
  {
    stepId: StepId.EMPTY_STATE,
    getAvailable: values => {
      const { categoryId, machineTypeId } =
        alignCategoryWithType(categories, values);
      const subcategory = machineTypeId || categoryId;
      return !data[subcategory];
      //return false; // manually disabling this step
    },
  },
  {
    stepId: StepId.POWER,
    getAvailable: withMotorOnly,
  },
  {
    stepId: StepId.HOUR,
    getAvailable: withMotorOnly,
  },
  {
    stepId: StepId.HECTARE,
    getAvailable: withMotorOnly,
  },
  {
    stepId: StepId.TIRES_CONDITION,
    getAvailable: withMotorOnly,
  },
  {
    stepId: StepId.SIGN_UP,
    getAvailable: values => {
      const forecastRecord = values
        .find(x => x.stepId === StepId.FORECAST);
      return !!forecastRecord;
    },
  },
  {
    stepId: StepId.ADD_ONS,
    getAvailable: values => {
      const { categoryId, machineTypeId } =
        alignCategoryWithType(categories, values);
      const machineType = machineTypeId || categoryId;
      const _addOns = addOns.find(x => x.subcategory === machineType);
      return !!_addOns;
      //const _addOns = makeGetAddOns(categories, addOns)(values);
      //return _addOns.length > 0;
    },
  }
];

export default availabilities;
