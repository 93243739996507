import ContentAction from './action-type.enum';

const initialContent = [];

export default function contentReducer(contentState = initialContent, action) {
  switch (action.type) {
    case ContentAction.CONTENT_SET: {
      const { stepId, isError, content } = action.payload;
      const newContent = contentState.map(x => (x.stepId !== stepId) && x);
      newContent.push({ stepId, isError, content });
      return newContent;
    }
    case ContentAction.CONTENT_CLEAR: {
      const { stepId } = action.payload;
      return contentState.filter(x => x.stepId !== stepId);
    }
    case ContentAction.CONTENT_CLEAR_ALL: {
      return [];
    }
    default: return contentState;
  }
}
