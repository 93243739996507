import React from 'react';

import './custom-checkbox-2.css';

export default function CustomCheckbox2({
  children, defaultChecked, checked, onChange, ...props
}) {
  return (
    <label className="custom-checkbox-2">
      { children }
      <input
        type="checkbox"
        className="custom-checkbox-2__input"
        defaultChecked={defaultChecked}
        checked={checked}
        onChange={onChange}
        {...props}
      />
      <span className="custom-checkbox-2__checkmark"></span>
    </label>
  );
}
