import { connect } from 'react-redux';

import ForecastManualView from './view';
import PredictorAction from '../../../modules/predictor/action-type.enum';
import StepId from '../../../data/step-id.enum';

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: email => {
      dispatch({
        type: PredictorAction.STEP_SELECT,
        payload: {
          stepId: StepId.SIGN_UP, // registering as SIGN_UP
          value: { email },
        },
      });
    },
  };
}

const ForecastManual = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForecastManualView);

export default ForecastManual;
