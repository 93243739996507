import React from 'react';

import '../styles/step-frame.css';
import parseOptions from '../../lib/parse-options';
import BaseListSelect from '../base-list-select';

export default function PlainListSelectView({ stepId, content }) {
  const options = parseOptions(stepId, content);
  
  return (
    <div className="step-frame">
      <div className="step-frame__content">
        <BaseListSelect options={options} />
      </div>
    </div>
  );
}
