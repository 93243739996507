import React, { useState, useEffect } from 'react';

import '../styles/step-frame.css';
import parseOptions from '../../lib/parse-options';
import BaseListSelect from '../base-list-select';
import SearchBar from '../search-bar';

function matchesRange(targetNum, option) {
  const { min, max } = option.opts;
  const matchesMin = targetNum >= min;
  const matchesMax = max ? targetNum <= max : true;
  return matchesMin && matchesMax;
}

function getQueryNumber(query) {
  const str = query.trim();
  if (!str || Number.isNaN(+str)) {
    return null;
  }
  return +str;
}

export default function NumberListSelectView({
  stepId, content, searchPlaceholder, onSelect,
}) {
  const [query, setQuery] = useState('');

  // resetting `query` for new step
  useEffect(() => setQuery(''), [stepId]);

  function handleSubmit(_query) {
    const queryNum = getQueryNumber(_query);
    if (queryNum !== null) {
      const option = options.find(x => matchesRange(queryNum, x));
      option && onSelect(stepId, option);
    }
  }

  const options = parseOptions(stepId, content)
    .filter(x => {
      const queryNum = getQueryNumber(query);
      if (queryNum === null) {
        return x;
      }
      return matchesRange(queryNum, x);
    });
  
  return (
    <div className="step-frame">
      <div className="step-frame__content">
        <SearchBar
          placeholder={searchPlaceholder}
          query={query}
          onQueryChange={setQuery}
          onSubmit={handleSubmit}
        />
        <BaseListSelect options={options} />
      </div>
    </div>
  );
}
