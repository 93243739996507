import React from 'react';

import './custom-checkbox.css';

export default function CustomCheckbox({ label, defaultChecked, checked, onChange }) {
  return (
    <label className="custom-checkbox">
      <span className="custom-checkbox__label">
        { label }
      </span>
      <input
        type="checkbox"
        className="custom-checkbox__input"
        defaultChecked={defaultChecked}
        checked={checked}
        onChange={onChange}
      />
      <span className="custom-checkbox__checkmark"></span>
    </label>
  );
}
