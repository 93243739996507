/**
 * Production years data.
 */

const startYear = 1990;
const currentYear = (new Date()).getFullYear();
const years = [];

for (let i = startYear; i <= currentYear; i++) {
  const yearOption = { id: i, label: i };
  years.push(yearOption);
}

export default years;
