import { connect } from 'react-redux';

import PlainListSelectView from './view';

function mapStateToProps(state) {
  return {
    stepId: state.predictor.step.id,
    content: state.predictor.content,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

const PlainListSelect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlainListSelectView);

export default PlainListSelect;
