import React from 'react';

import './progress.scss';
import traktorIcon from './traktor-icon.svg';

function renderLeftNode(step) {
  return step ? (
    <span className="milestone filled"></span>
  ) : (
    <div className="tractor-icon">
      <img className="w-100" src={traktorIcon} alt="tractor icon step 1" />
    </div>
  );
}

function renderLeftLine(step) {
  switch (step) {
    case 'email':
    case 'question':
    case 'finish': return <div className="progress-line filled"></div>;
    default: return <div className="progress-line short"></div>;
  }
}

function renderCenterNode(step) {
  switch (step) {
    case 'email': {
      return (
        <div className="tractor-icon">
          <img className="w-100" src={traktorIcon} alt="tractor icon step 2" />
        </div>
      );
    }
    case 'question':
    case 'finish': return <span className="milestone filled"></span>;
    default: return <span className="milestone"></span>;
  }
}

function renderRightLine(step) {
  switch (step) {
    case 'question': return <div className="progress-line filled short"></div>;
    case 'finish': return <div className="progress-line filled"></div>;
    default: return <div className="progress-line"></div>;
  }
}

function renderRightNode(step) {
  switch (step) {
    case 'question': {
      return (
        <div className="tractor-icon">
          <img className="w-100" src={traktorIcon} alt="tractor icon step 3" />
        </div>
      );
    }
    case 'finish': return <span className="milestone filled"></span>;
    default: return <span className="milestone"></span>;
  }
}

export default function Progress(props) {
  const step = props.step;
  return (
    <div id="traktorProgress" className="py-4">
      { renderLeftNode(step) }
      { renderLeftLine(step) }
      { renderCenterNode(step) }
      { renderRightLine(step) }
      { renderRightNode(step) }
    </div>
  );
}
