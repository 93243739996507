import { connect } from 'react-redux';

import MainView from './view';

function mapStateToProps(state) {
  return {
    stepType: state.predictor.step.type,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

const Main = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainView);

export default Main;
