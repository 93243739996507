import StepId from '../step-id.enum';
import makeGetMachineTypes from '../getters/machine-types/make-get-machine-types';
//import makeGetBrands from '../getters/brands/make-get-brands';  // old version
//import makeGetModels from '../getters/models/make-get-models';  // old version
//import makeGetAddOns from '../getters/add-ons/make-get-add-ons';  // old version
import alignCategoryWithType from '../getters/helpers/align-category-with-type';

import data from './data';
import categories from './categories';
//import brands from './brands';            // JS-based, old version
//import allBrands from './brands/json-merged';  // JSON-based, old version
import powerOptions from './power';
import years from './years';
import hours from './hours';
import hectares from './hectares';
import conditions from './conditions';
import tiresConditions from './conditions/tires';

//import addOns from './add-ons';   // JS-based, old version
//import addOns from './add-ons/add-ons.json';  // JSON-based, old version
import addOns from './add-ons/data.json';   // next version

import getForecastContent from './forecast/get-forecast-content';     // prod
//import getForecastContent from './forecast/get-forecast-content-test';  // test

import getFinishContent from './finish/get-finish-content';     // prod
//import getFinishContent from './finish/get-finish-content-test';  // test


// sort compare function
const compare = (a, b) => a.label > b.label ? 1 : -1;


/**
 * Production content getters.
 */
const contentGetters = [
  {
    stepId: StepId.CATEGORY,
    getContent: values => {
      return categories;
    }
  },
  {
    stepId: StepId.MACHINE_TYPE,
    getContent: makeGetMachineTypes(categories),
  },
  {
    stepId: StepId.BRAND,
    getContent: values => {
      const { categoryId, machineTypeId } =
        alignCategoryWithType(categories, values);
      const subcategory = machineTypeId || categoryId;
      return Object.values(data[subcategory])
        .filter(x => !!x.machine_name)
        .filter(x => x.machine_name !== 'sonstige / other')
        .map(x => ({ id: x.machine_name, label: x.display_name }))
        .sort(compare);
    },
  },
  {
    stepId: StepId.MODEL,
    getContent: values => {
      const { categoryId, machineTypeId } =
        alignCategoryWithType(categories, values);
      const subcategory = machineTypeId || categoryId;
      const brand = values.find(x => x.stepId === StepId.BRAND).value.id;
      return data[subcategory][brand].models
        .filter(x => !!x.machine)
        .reduce((acc, x) => {
          const existing = acc.find(y => y.machine === x.machine);
          if (!existing) {
            acc.push(x);
          }
          return acc;
        }, [])
        .map(x => ({ id: x.machine, label: x.display }))
        .sort(compare);
    },
  },
  {
    stepId: StepId.POWER,
    getContent: values => powerOptions,
  },
  {
    stepId: StepId.YEAR,
    getContent: values => {
      return years;
    },
  },
  {
    stepId: StepId.HOUR,
    getContent: values => {
      return hours;
    },
  },
  {
    stepId: StepId.HECTARE,
    getContent: values => {
      return hectares;
    },
  },
  {
    stepId: StepId.CONDITION,
    getContent: values => {
      return conditions;
    },
  },
  {
    stepId: StepId.TIRES_CONDITION,
    getContent: values => tiresConditions,
  },
  {
    stepId: StepId.ADD_ONS,
    //getContent: makeGetAddOns(categories, addOns),
    getContent: values => {
      const { categoryId, machineTypeId } =
        alignCategoryWithType(categories, values);
      const subcategory = machineTypeId || categoryId;
      const _addOns = addOns.find(x => x.subcategory === subcategory);
      return _addOns.addOns;
      /*const features = data[machineType].most_important_features;
      const details = features.filter(x => x.match(/^details_/));
      const addOns = details.map(x => {
        const payload = x.split('details_')[1];
        const label = payload.replace('_', ' ');
        return { id: x, label };
      });
      return addOns;*/
    },
  },
  {
    stepId: StepId.FORECAST,
    getContent: getForecastContent,
  },
  {
    stepId: StepId.FINISH,
    getContent: getFinishContent,
  },
];

export default contentGetters;
