import { connect } from 'react-redux';

import BaseListSelectView from './view';
import PredictorAction from '../../modules/predictor/action-type.enum';

function mapStateToProps(state) {
  return {
    stepId: state.predictor.step.id,
    values: state.predictor.values,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSelect: (stepId, option) => {
      dispatch({
        type: PredictorAction.STEP_SELECT,
        payload: {
          stepId,
          value: option,
        },
      });
    },
  };
}

const BaseListSelect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BaseListSelectView);

export default BaseListSelect;
