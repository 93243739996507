import { call, select, take } from 'redux-saga/effects';

import PredictorAction from './action-type.enum';
import { valueSelector } from './selectors';
import valueRegisterSaga from '../values/value-register-saga';
import getStepSaga from '../step/get-step-saga';
import loadContentSaga from '../content/load-content-saga';
import stepSetSaga from '../step/step-set-saga';

export default function* stepSelectSaga(predictorParams) {
  while (true) {
    const action = yield take(PredictorAction.STEP_SELECT);

    // registering value
    const { stepId, targetStepId, value } = action.payload;
    yield call(valueRegisterSaga, stepId, value);

    // getting registered values
    const values = yield select(valueSelector);

    // getting next available step
    const { contentGetters } = predictorParams;
    const stepData = yield call(getStepSaga, predictorParams, values, false, targetStepId);
    const { step, index } = stepData;
    const { id } = step;

    // setting step
    yield call(stepSetSaga, step, index);

    // loading content of the step
    yield call(loadContentSaga, contentGetters, id, values);
  }
}
