import React from 'react';

import './loading-screen.css';

export default function LoadingScreen() {
  return (
    <div className="loading-screen">
      <i className="loading-screen__spinner fas fa-circle-notch fa-spin"></i>
    </div>
  );
}
